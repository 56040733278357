/**
 * EpiCurrents Viewer Vuex store EEG module.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const eeg = {
    moduleName: {
        code: 'eeg',
        full: 'Electroencephalography',
        short: 'EEG',
    },
    resources: [],
};
const getters = {
    activeEegResource: (state) => () => {
        for (const resource of eeg.resources) {
            if (resource.isActive) {
                return resource;
            }
        }
        return null;
    }
};
export { eeg, getters };
