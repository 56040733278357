/**
 * EpiCurrents Viewer Vuex store.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { createStore } from 'vuex';
// Actions
import { actions } from './actions';
// Mutations
import { mutations } from './mutations';
// Modules - these are named in all capital letters in the store object
import { app as appModule, getters as appGetters } from './modules/app';
import { eeg as eegModule, getters as eegGetters } from './modules/eeg';
import { emg as emgModule, getters as emgGetters } from './modules/emg';
import { ncs as ncsModule, getters as ncsGetters } from './modules/ncs';
import { doc as docModule, getters as docGetters } from './modules/document';
import { meg as megModule, getters as megGetters } from './modules/meg';
import SETTINGS from 'CONFIG/Settings';
import { WebGlPlot } from 'LIB/plots';
import { PyodideLoader } from 'LIB/loaders/PyodideLoader';
import Log from 'scoped-ts-log';
const SCOPE = "Store";
const getters = {
    biosignalPlot: () => {
        if (!appModule.plots.biosignal) {
            appModule.plots.biosignal = new WebGlPlot();
        }
        return appModule.plots.biosignal;
    },
    activeResource: () => {
        return appGetters.getActiveResource(store.state)() ||
            docGetters.activeDocResource(store.state)() ||
            eegGetters.activeEegResource(store.state)() ||
            emgGetters.activeEmgResource(store.state)() ||
            ncsGetters.activeNcsResource(store.state)() ||
            megGetters.activeMegResource(store.state)();
    },
    ...appGetters,
    ...docGetters,
    ...eegGetters,
    ...emgGetters,
    ...ncsGetters,
    ...megGetters,
};
// Initial store values
const state = {
    // Modules
    APP: appModule,
    DOC: docModule,
    EEG: eegModule,
    EMG: emgModule,
    NCS: ncsModule,
    MEG: megModule,
    ONNX: null,
    PYODIDE: null,
    SETTINGS: SETTINGS,
    // Root properties
    containerId: '',
    isFullscreen: false,
    settingsOpen: false,
    showOverlay: false,
};
const store = createStore({
    state,
    actions,
    getters,
    mutations,
    devtools: false,
});
export default class AppStore {
    static isInitialized = false;
    /**
     * Initialize a Vuex store in the given Vue app instance.
     * @param appInstance Vue instance, will call vueInstance.use(store) if supplied
     * @param initValues optional values as an object of { field: value } pairs (eg. { appId: 'app', SETTINGS: { 'eeg.trace.margin.top': 10 } })
     * @returns Vuex store instance
     */
    static init = (appInstance, initValues = {}) => {
        if (AppStore.isInitialized) {
            Log.warn(`Tried to initialize an already initialized store.`, SCOPE);
            return store;
        }
        // FIRST set logging threshold, so all possible messages are seen
        Log.setPrintThreshold(store.state.SETTINGS.app.logThreshold);
        // Apply possible initial values
        for (const value of Object.entries(initValues)) {
            if (value[0] === 'SETTINGS') {
                for (const setting of Object.entries(value[1])) {
                    store.commit('set-settings-value', { field: setting[0], value: setting[1] });
                }
            }
            else {
                store.state[value[0]] = value[1];
            }
        }
        // Load possible local settings
        const local = window.localStorage.getItem('settings');
        if (local) {
            // Go through available modules
            mod_loop: for (const [mod, items] of Object.entries(JSON.parse(local))) {
                const MODULE = SETTINGS[mod];
                field_loop: for (const [field, value] of Object.entries(items)) {
                    if (!MODULE._userDefinable) {
                        continue mod_loop;
                    }
                    // Check that setting can be modified
                    for (const [uField, uConst] of Object.entries(MODULE._userDefinable)) {
                        if (uField === field && value?.constructor === uConst) {
                            store.commit('set-settings-value', { field: `${mod}.${field}`, value: value });
                            Log.debug(`Applied local value ${value} to settings field ${mod}.${field}`, SCOPE);
                            continue field_loop;
                        }
                    }
                    Log.warn(`Setting ${mod}.${value} cannot be set by the user or the value type is incorrect.`, SCOPE);
                }
            }
        }
        // Load optional modules
        if (SETTINGS.modules.MNE) {
            state.PYODIDE = new PyodideLoader();
        }
        // Use in provided Vue app instance
        if (appInstance) {
            appInstance.use(store);
        }
        AppStore.isInitialized = true;
        return store;
    };
    static get APP() {
        return store?.state.APP;
    }
    static get PYODIDE() {
        return store?.state.PYODIDE;
    }
    static get SETTINGS() {
        return SETTINGS;
    }
    static get containerId() {
        return store?.state.containerId || '';
    }
    static set containerId(value) {
        if (store) {
            store.state.containerId = value;
        }
    }
    static get isFullscreen() {
        return store?.state.isFullscreen || false;
    }
    static set isFullscreen(value) {
        if (store) {
            store.state.isFullscreen = value;
        }
    }
    static get settingsOpen() {
        return store?.state.settingsOpen || false;
    }
    static set settingsOpen(value) {
        if (store) {
            store.state.settingsOpen = value;
        }
    }
    static get showOverlay() {
        return store?.state.showOverlay || false;
    }
    static set showOverlay(value) {
        if (store) {
            store.state.showOverlay = value;
        }
    }
}
export { store };
