/**
 * EpiCurrents Viewer biosignal trace color.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
export class PlotColor {
    _a;
    _b;
    _g;
    _r;
    get a() {
        return this._a;
    }
    get array() {
        return [
            this._r, this._g, this._b, this._a
        ];
    }
    get b() {
        return this._b;
    }
    get g() {
        return this._g;
    }
    get r() {
        return this._r;
    }
    constructor(r, g, b, a) {
        this._a = a;
        this._b = b;
        this._g = g;
        this._r = r;
    }
}
