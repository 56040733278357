/**
 * EpiCurrents Viewer markdown file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import GenericFileLoader from '../lib/loaders/GenericFileLoader';
import Log from 'scoped-ts-log';
const SCOPE = 'PdfFileLoader';
export default class PdfFileLoader extends GenericFileLoader {
    constructor() {
        super(SCOPE, [], ['.pdf']);
    }
    async loadFile(source, config = {}) {
        const file = source.file || source;
        Log.debug(`Adding a PDF file ${file.webkitRelativePath}.`, SCOPE);
        const studyFile = {
            file: file,
            format: 'pdf',
            mime: config?.mime || 'application/pdf',
            name: config?.name || file.name || '',
            type: '',
            url: config?.url || URL.createObjectURL(file),
        };
        this._study.type = 'doc:pdf';
        this._study.format = 'pdf';
        this._study.files.push(studyFile);
        return studyFile;
    }
    async loadFileUrl(source, config = {}) {
        const url = source.url || source;
        Log.debug(`Adding a PDF url ${url}.`, SCOPE);
        const studyFile = {
            file: null,
            format: 'pdf',
            mime: config?.mime || 'application/pdf',
            name: config?.name || '',
            type: '',
            url: config?.url || url,
        };
        this._study.type = 'doc:pdf';
        this._study.format = 'pdf';
        this._study.files.push(studyFile);
        return studyFile;
    }
}
