/**
 * EDF recording headers.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
import GenericBiosignalHeaders from 'LIB/common/GenericBiosignalHeaders';
const SCOPE = 'EdfHeaders';
export default class EdfRecording extends GenericBiosignalHeaders {
    _headers;
    _physicalSignals;
    _rawSignals;
    constructor(headers, rawSignals, physicalSignals, annotations = [], dataGaps = new Map(), fileType = 'edf') {
        // Calculate record size
        // In case of possible BDF support in the future
        const SampleType = fileType === 'edf' ? Int16Array : Int16Array;
        let maxSr = 0;
        let dataRecordSize = 0;
        const signalProps = [];
        for (const sig of headers.signalInfo) {
            dataRecordSize += sig.sampleCount * SampleType.BYTES_PER_ELEMENT;
            const sigSr = sig.sampleCount / headers.dataRecordDuration;
            if (sigSr > maxSr) {
                maxSr = sigSr;
            }
            signalProps.push({
                label: sig.label,
                physicalUnit: sig.physicalUnit,
                prefiltering: sig.prefiltering,
                sampleCount: sig.sampleCount,
                samplingRate: sigSr,
            });
        }
        super('edf', headers.localRecordingId, headers.patientId, headers.dataRecordCount, headers.dataRecordDuration, dataRecordSize, headers.signalCount, signalProps, headers.recordingDate, annotations, dataGaps);
        this._headers = headers;
        this._physicalSignals = physicalSignals;
        this._rawSignals = rawSignals;
    }
    /**
     * The EDF header record.
     */
    get headers() {
        return this._headers;
    }
    /**
     * Whether this recording is discontinuous.
     */
    get isDiscontinuous() {
        return this._headers.discontinuous;
    }
    /**
     * Whether this recording uses the EDF+ specification.
     */
    get isEdfPlus() {
        return this._headers.edfPlus;
    }
    /**
     * Size of the header record in bytes.
     */
    get size() {
        return this._headers.headerRecordBytes;
    }
    /**
    * Get the physical maximum for a given signal index.
    * @param index - index of the signal
    * @return physical signal max, null if signal index out of range
    */
    getSignalPhysicalMax(index) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return physical maximum.`, SCOPE);
            return null;
        }
        return this._headers.signalInfo[index].physicalMaximum;
    }
    /**
    * Get the physical minimum for a given signal index.
    * @param index - index of the signal
    * @return physical signal min, null if signal index out of range
    */
    getSignalPhysicalMin(index) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return physical minimum.`, SCOPE);
            return null;
        }
        return this._headers.signalInfo[index].physicalMinimum;
    }
    /**
    * Get the physical (scaled) signal at a given index and record.
    * @param index - index of the signal
    * @param record - index of the record
    * @return the physical signal in Float32, null if signal or record index out of range
    */
    getPhysicalSignal(index, record) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return physical signal.`, SCOPE);
            return null;
        }
        if (record < 0 && record >= this._physicalSignals[index].length) {
            Log.warn(`Record index ${record} is out of range, cannot return physical signal.`, SCOPE);
            return null;
        }
        return this._physicalSignals[index][record];
    }
    /**
    * Get concatenated contiguous records of a given signal, the index of the
    * first record and the number of records to concat.
    * Notice: this allocates a new buffer of an extented size.
    * @param index - index of the signal
    * @param recordStart - index of the record to start with
    * @param howMany - Number of records to concatenate
    * @return the physical signal in Float32, null if signal or record index out of range
    */
    getPhysicalSignalConcatRecords(index, recordStart = -1, howMany = -1) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot concatenate signal records.`, SCOPE);
            return null;
        }
        if (recordStart < 0 && recordStart >= this._physicalSignals[index].length) {
            Log.warn(`Record index ${recordStart} is out of range, cannot concatenate signal records.`, SCOPE);
            return null;
        }
        if (recordStart === -1) {
            recordStart = 0;
        }
        if (howMany === -1) {
            howMany = this._physicalSignals[index].length - recordStart;
        }
        else {
            // we still want to check if what the user put is not out of bound
            if (recordStart + howMany > this._physicalSignals[index].length) {
                Log.debug("The number of requested records to concatenate is too large. Returning only available records.", SCOPE);
                howMany = this._physicalSignals[index].length - recordStart;
            }
        }
        const recordEnd = recordStart + howMany - 1;
        if (recordEnd === recordStart) {
            Log.debug("No more records to concatenate.", SCOPE);
            return new Float32Array();
        }
        let totalSize = 0;
        for (var i = recordStart; i < recordStart + howMany; i++) {
            totalSize += this._physicalSignals[index][i].length;
        }
        const concatSignal = new Float32Array(totalSize);
        let offset = 0;
        for (let i = recordStart; i < recordStart + howMany; i++) {
            concatSignal.set(this._physicalSignals[index][i], offset);
            offset += this._physicalSignals[index][i].length;
        }
        return concatSignal;
    }
    /**
    * Get the raw (digital) signal at a given index and record.
    * @param index - index of the signal
    * @param record - index of the record
    * @returnthe physical signal in Int16, null if signal or record index out of range
    */
    getRawSignal(index, record) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return raw signal.`, SCOPE);
            return null;
        }
        if (record < 0 && record >= this._rawSignals[index].length) {
            Log.warn(`Record index ${record} is out of range, cannot return raw signal.`, SCOPE);
            return null;
        }
        return this._rawSignals[index][record];
    }
    /**
    * Get the value of the reserved field, global (from header) or specific to a signal.
    * Notice: reserved are rarely used.
    * @param index - if not specified, get the header's reserved field. If [0, signalCount[ get the reserved field specific for the given signal
    * @return the data of the reserved field.
    */
    getReservedField(index = -1) {
        if (index === -1) {
            return this._headers.reserved;
        }
        else {
            if (index >= 0 && index < this._headers.signalInfo.length) {
                return this._headers.signalInfo[index]?.reserved;
            }
        }
        return null;
    }
    /**
    * Get the digital maximum for a given signal index.
    * @param index - index of the signal
    * @return digital signal max, null if signal index out of range
    */
    getSignalDigitalMax(index) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return digital maximum.`, SCOPE);
            return null;
        }
        return this._headers.signalInfo[index].digitalMaximum;
    }
    /**
    * Get the digital minimum for a given signal index.
    * @param index - index of the signal
    * @return digital signal min, null if signal index out of range
    */
    getSignalDigitalMin(index) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return digital minimum.`, SCOPE);
            return null;
        }
        return this._headers.signalInfo[index].digitalMinimum;
    }
    /**
    * Get the unit prefiltering info for a given signal index.
    * @param index - index of the signal, null if signal index out of range
    * @return the prefiltering info
    */
    getSignalPrefiltering(index) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return signal prefiltering.`, SCOPE);
            return null;
        }
        return this._headers.signalInfo[index].prefiltering;
    }
    /**
    * Get the transducer type info for a given signal index.
    * @param index - index of the signal
    * @return the transducer type, null if signal index out of range
    */
    getSignalTransducerType(index) {
        if (index < 0 || index >= this._headers.signalInfo.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return signal transducer type.`, SCOPE);
            return null;
        }
        return this._headers.signalInfo[index].transducerType;
    }
}
