/**
 * EpiCurrents Viewer EMG settings.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
export const EMG = {
    annotations: {
        color: [0, 0, 1, 0.75],
        convertPatterns: [],
        idColors: {},
        ignorePatterns: [],
        typeColors: {},
        width: 1,
    },
    antialiasing: false,
    border: {
        bottom: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 1,
        },
        left: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
        right: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
        top: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
    },
    channelSpacing: 1,
    cursor: {
        active: {
            color: [0, 0, 0.5, 0.75],
            style: 'solid',
            width: 2,
        },
        focused: {
            color: [0, 0, 0.5, 0.75],
            style: 'solid',
            width: 2,
        },
        inactive: {
            color: [0, 0, 0.5, 0.75],
            style: 'solid',
            width: 2,
        },
    },
    defaultMontages: {},
    defaultSetups: [],
    defaultTriggerValue: -100,
    displayPolarity: -1,
    downsampleLimit: 0,
    filterPaddingSeconds: 0.05,
    filters: {
        highpass: {
            availableValues: [],
            default: 0,
        },
        lowpass: {
            availableValues: [],
            default: 0,
        },
        notch: {
            availableValues: [],
            default: 0,
        },
    },
    findingGroups: [
        'spontaneus',
        'reactive',
        'activation',
    ],
    findingTypes: {
        activation: {
            default: 0,
            label: 'Fibr.',
            name: 'Fibrillations',
            values: [0, 1, 2, 3, 4, 5],
        },
    },
    groupSpacing: 1,
    jitter: {
        highlight: {
            borderColor: [0, 0, 0, 0],
            borderWidth: 0,
            fillColor: [1, 1, 0.5, 1],
            height: 10,
        },
        pair: {
            borderColor: [0, 0.5, 0, 1],
            borderWidth: 1,
            fillColor: [0, 1, 0, 1],
            size: 8,
        },
        rowHeight: 10,
        trigger: {
            borderColor: [0, 0.25, 0.5, 1],
            borderWidth: 1,
            fillColor: [0, 0.5, 1, 1],
            size: 8,
        },
        upcoming: {
            borderColor: [0.5, 0.5, 0.5, 1],
            borderWidth: 1,
            fillColor: [0.75, 0.75, 0.75, 1],
            size: 8,
        },
    },
    majorGrid: {
        show: true,
        color: [0, 0, 0, 0.25],
        style: 'solid',
        width: 2,
    },
    markerColor: [0, 0, 0, 0.5],
    markerSize: 2,
    masterAxis: 'x',
    minorGrid: {
        show: true,
        color: [0, 0, 0, 0.15],
        style: 'solid',
        width: 1,
    },
    montages: {
        cacheMax: 0,
        preCache: false,
    },
    navigator: {
        borderColor: [0, 0, 0, 0.1],
        signalColor: [0, 0, 0, 0.5],
        theme: 'default',
        tickColor: [0, 0, 0, 0.05],
        outOfViewBackground: [0, 0, 0, 0.05],
        outOfViewSignal: [1, 1, 1, 0.6],
    },
    pageLength: 0.1,
    results: {
        display: true,
        width: 400,
    },
    scaleUnit: 'div',
    sensitivity: {
        div: {
            availableValues: [5, 7, 10, 20, 30, 50, 70, 100, 200, 300, 500, 1_000, 2_000, 3_000, 5_000],
            default: 50,
        },
        page: {
            availableValues: [50, 70, 100, 200, 300, 500, 700, 1_000, 2_000, 3_000, 5_000, 10_000, 20_000, 30_000, 50_000],
            default: 500,
        },
    },
    sensitivityUnit: 'div',
    showHiddenChannels: false,
    showMissingChannels: false,
    timebase: {
        div: {
            availableValues: [0.5, 1, 2, 3, 5, 7, 10, 15, 20, 25, 50, 100],
            default: 10,
        },
        page: {
            availableValues: [5, 10, 20, 30, 50, 70, 100, 150, 200, 250, 500, 1_000],
            default: 100,
        },
    },
    timebaseUnit: 'div',
    timeline: {
        labelSpacing: 0,
    },
    trace: {
        color: [0.2, 0.2, 0.2, 1],
        theme: 'default',
        width: 1,
    },
    xDivCount: 10,
    yDivCount: 10,
    yPadding: 1,
};
