/**
 * EpiCurrents Viewer generic asset.
 * This is the root class that all other classes extend.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import { safeObjectFrom } from 'LIB/util/general';
import Log from 'scoped-ts-log';
const SCOPE = "GenericAsset";
export default class GenericAsset {
    static CONTEXTS = safeObjectFrom({
        BIOSIGNAL: 'sig',
        COMPONENT: 'cmp',
        DATASET: 'dat',
        DOCUMENT: 'doc',
        LOADER: 'ldr',
        PRESENTATION: 'prs',
        UNKNOWN: 'unk',
    });
    static TYPES = safeObjectFrom({
        BIOSIGNAL: [
            'eeg',
            'emg',
            'meg',
            'ncs',
        ],
        COMPONENT: [],
        DATASET: [],
        DOCUMENT: [
            'htm',
            'pdf',
        ],
        LOADER: [],
        PRESENTATION: [],
        UNKNOWN: [],
    });
    _context;
    _id;
    _isActive = false;
    _name;
    _propertyUpdateHandlers = [];
    _type;
    constructor(name, context, type) {
        this._id = Math.random().toString(36).substring(2, 10);
        this._context = GenericAsset.CONTEXTS.UNKNOWN;
        this._type = '';
        for (const [contextKey, validContext] of Object.entries(GenericAsset.CONTEXTS)) {
            if (validContext === context) {
                this._context = context;
                if (Object.values(GenericAsset.TYPES[contextKey]).includes(type)) {
                    this._type = type;
                }
                break;
            }
        }
        this._name = name;
    }
    get context() {
        return this._context;
    }
    set context(value) {
        this._context = value;
        this.onPropertyUpdate('context');
    }
    get id() {
        return this._id;
    }
    get isActive() {
        return this._isActive;
    }
    set isActive(value) {
        this._isActive = value;
        this.onPropertyUpdate('is-active');
    }
    get name() {
        return this._name;
    }
    get type() {
        return this._type;
    }
    set type(value) {
        this._type = value;
        this.onPropertyUpdate('type');
    }
    ///////////////////////////////////////////////////
    //                   METHODS                     //
    ///////////////////////////////////////////////////
    addPropertyUpdateHandler(property, handler, caller) {
        for (const update of this._propertyUpdateHandlers) {
            if (property === update.property && handler === update.handler) {
                // Don't add the same handler twice
                return;
            }
        }
        this._propertyUpdateHandlers.push({
            caller: caller || null,
            handler: handler,
            pattern: new RegExp(`^${property}$`, 'i'),
            property: property,
        });
        Log.debug(`Added a handler for ${property}.`, SCOPE);
    }
    onPropertyUpdate(property, newValue, oldValue) {
        for (const update of this._propertyUpdateHandlers) {
            if (update.property === property || property.match(update.pattern)) {
                Log.debug(`Executing ${property} handler${update.caller ? ' for ' + update.caller : ''}.`, SCOPE);
                update.handler(newValue, oldValue);
            }
        }
    }
    removeAllPropertyUpdateHandlers() {
        Log.debug(`Removing all ${this._propertyUpdateHandlers.splice(0).length} property update handlers.`, SCOPE);
    }
    removeAllPropertyUpdateHandlersFor(caller) {
        for (let i = 0; i < this._propertyUpdateHandlers.length; i++) {
            const update = this._propertyUpdateHandlers[i];
            if (caller === update.caller) {
                this._propertyUpdateHandlers.splice(i, 1);
                i--;
                Log.debug(`Removed ${update.property} handler for ${caller}.`, SCOPE);
            }
        }
    }
    removePropertyUpdateHandler(property, handler) {
        for (let i = 0; i < this._propertyUpdateHandlers.length; i++) {
            const update = this._propertyUpdateHandlers[i];
            if (property === update.property && handler === update.handler) {
                this._propertyUpdateHandlers.splice(i, 1);
                Log.debug(`Removed ${property} handler${update.caller ? ' for ' + update.caller : ''}.`, SCOPE);
                return;
            }
        }
        Log.debug(`Cound not locate the requsted ${property} handler.`, SCOPE);
    }
}
