/**
 * EpiCurrents Viewer biosignal trace line.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
const SCOPE = 'WebGlPlotTrace';
export class WebGlPlotTrace {
    _amplification = 1;
    _buffer = 0;
    _color;
    _coordinates = 0;
    _downsampleFactor = 0;
    _offset;
    _parent;
    _polarity;
    _render = true;
    _samplesPerPx;
    _samplingRate;
    _sensitivity;
    _xyPairs = new Float32Array();
    get amplification() {
        return this._amplification;
    }
    set amplification(value) {
        this._amplification = value;
    }
    get buffer() {
        return this._buffer;
    }
    set buffer(buffer) {
        this._buffer = buffer;
    }
    get color() {
        return this._color;
    }
    get coordinates() {
        return this._coordinates;
    }
    set coordinates(coords) {
        this._coordinates = coords;
    }
    get length() {
        return this._xyPairs.length / 2;
    }
    get offset() {
        return this._offset;
    }
    get polarity() {
        return this._polarity;
    }
    set polarity(value) {
        this._polarity = value;
    }
    get render() {
        return this._render;
    }
    get samplingRate() {
        return this._samplingRate;
    }
    get sensitivity() {
        return this._sensitivity;
    }
    set sensitivity(value) {
        this._sensitivity = value;
    }
    get xy() {
        return this._xyPairs;
    }
    constructor(parent, color, length, sensitivity, samplingRate, samplesPerPx, downsampleFactor, polarity, amplification, offset) {
        this._color = color;
        this._downsampleFactor = downsampleFactor;
        this._offset = offset;
        this._parent = parent;
        this._polarity = polarity;
        this._samplesPerPx = samplesPerPx;
        this._samplingRate = samplingRate;
        this._sensitivity = sensitivity;
        this._amplification = amplification;
        this._xyPairs = new Float32Array(2 * Math.floor(length));
        this.initData();
    }
    /**
     * Get the current signal data stored into this trace.
     * @returns data values of the trace as Float32Array
     */
    getData() {
        return this._xyPairs.filter((value, index) => (index % 2));
    }
    initData(step = 1) {
        // WebGL canvas clip space dimensions are always -1 to 1
        const clipSpaceStep = 2 / this.length;
        // Initialize the array with the given step, i.e. set x-values to be `step` apart
        for (let i = 0; i < this.length; i++) {
            this._xyPairs[i * 2] = (i * step) * clipSpaceStep - 1;
            this._xyPairs[i * 2 + 1] = 0;
        }
    }
    setData(data) {
        if (typeof data === 'number') {
            // Set all datapoints to same value
            for (let i = 0; i < this.length; i++) {
                this._xyPairs[i * 2 + 1] = data;
            }
            return;
        }
        const dataLoop = Math.floor(Math.min(data.length / this._downsampleFactor, this.length));
        for (let i = 0; i < dataLoop; i++) {
            this._xyPairs[i * 2 + 1] = data[i * this._downsampleFactor];
        }
        // Fill any leftover with zeroes
        for (let j = dataLoop; j < this.length; j++) {
            this._xyPairs[j * 2 + 1] = 0;
        }
    }
    setSensitivity(value) {
        if (value <= 0) {
            Log.error(`Sensitivity must be greater than zero, ${value} was given.`, SCOPE);
            return;
        }
        this._sensitivity = value;
    }
}
