/**
 * EpiCurrents Viewer Vuex store EEG module.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const doc = {
    moduleName: {
        code: 'doc',
        full: 'Documents',
        short: 'Docs',
    },
    resources: [],
};
const getters = {
    activeDocResource: (state) => () => {
        for (const resource of doc.resources) {
            if (resource.isActive) {
                return resource;
            }
        }
        return null;
    },
};
export { doc, getters };
