/**
 * EpiCurrents Viewer EDF file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import GenericFileLoader from '../lib/loaders/GenericFileLoader';
import EdfDecoder from 'LIB/loaders/edf/EdfDecoder';
import Log from 'scoped-ts-log';
import { secondsToTimeString } from 'LIB/util/conversions';
import AppStore from "STORE";
const SCOPE = 'EdfFileLoader';
export default class EdfFileLoader extends GenericFileLoader {
    _decoder = new EdfDecoder();
    constructor() {
        super(SCOPE, [], ['.edf']);
    }
    _loadEdfHeader(source, config = {}) {
        this._decoder.setInput(source);
        this._decoder.decodeHeader(true);
        const edfRecording = this._decoder.output;
        const recType = edfRecording.isEdfPlus && edfRecording.isDiscontinuous
            ? `EDF+ (discontinuous) file header parsed:`
            : edfRecording.isEdfPlus
                ? `EDF+ (continuous) file header parsed:`
                : `EDF file header parsed:`;
        Log.debug([
            recType,
            `${edfRecording.signalCount} signals,`,
            `${edfRecording.dataRecordCount} records,`,
            `${edfRecording.dataRecordDuration} seconds/record,`,
            `${secondsToTimeString(edfRecording.totalDuration)} duration.`,
        ], SCOPE);
        // Try to fetch metadata from header.
        // Saving metadata separately is important in case libraries are added or changed later.
        this._study.meta.patientId = this._study.meta.patientId || edfRecording.patientId || null;
        this._study.meta.recordId = this._study.meta.recordId || edfRecording.recordingId || null;
        this._study.meta.startDate = this._study.meta.startDate || edfRecording.recordingStartDate || null;
        this._study.meta.nDataRecords = edfRecording.dataRecordCount || null;
        this._study.meta.recordLen = edfRecording.dataRecordDuration || null;
        this._study.meta.nSignals = edfRecording.signalCount || null;
        return this._study.meta;
    }
    async _loadEdfSignals(source, config = {}) {
        this._decoder.appendInput(source);
        this._decoder.decodeHeader();
        const fullHeader = this._decoder.output;
        // Select method based on file loader.
        // For automatic determination of study modality.
        let eegSignalCount = 0;
        let megSignalCount = 0;
        let polySignalCount = 0;
        // We should not have loaded large files with decoder, so cache the whole signal data
        const totalRecords = fullHeader.dataRecordCount;
        const signals = [];
        let subType = '';
        for (let i = 0; i < fullHeader.signalCount; i++) {
            // Try to determine amplification from unit
            const unitLow = fullHeader.getSignalPhysicalUnit(i)?.toLowerCase();
            const amplification = unitLow === 'uv' || unitLow === 'µv' ? 1
                : unitLow === 'mv' ? 1_000 : unitLow === 'v' ? 1_000_000 : 1;
            const label = fullHeader.getSignalLabel(i) || '';
            let sigType = config?.signals ? config.signals[i]?.type : undefined;
            if (!sigType) {
                // See if signal type is EEG
                for (const typeLbl of AppStore.SETTINGS.eeg.labelMatchers.eeg) {
                    if (label.toLowerCase().indexOf(typeLbl.toLowerCase()) > -1) {
                        sigType = 'eeg';
                        eegSignalCount++;
                        break;
                    }
                }
            }
            if (!sigType) {
                // See if signal type is EKG
                for (const typeLbl of AppStore.SETTINGS.eeg.labelMatchers.ekg) {
                    if (label.toLowerCase().indexOf(typeLbl.toLowerCase()) > -1) {
                        sigType = 'ekg';
                        polySignalCount++;
                        break;
                    }
                }
            }
            if (!sigType) {
                // See if signal type is EMG
                for (const typeLbl of AppStore.SETTINGS.eeg.labelMatchers.emg) {
                    if (label.toLowerCase().indexOf(typeLbl.toLowerCase()) > -1) {
                        sigType = 'emg';
                        polySignalCount++;
                        break;
                    }
                }
            }
            if (!sigType) {
                // See if signal type is EOG
                for (const typeLbl of AppStore.SETTINGS.eeg.labelMatchers.eog) {
                    if (label.toLowerCase().indexOf(typeLbl.toLowerCase()) > -1) {
                        sigType = 'eog';
                        polySignalCount++;
                        break;
                    }
                }
            }
            if (!sigType) {
                // See if signal type is respiration
                for (const typeLbl of AppStore.SETTINGS.eeg.labelMatchers.res) {
                    if (label.toLowerCase().indexOf(typeLbl.toLowerCase()) > -1) {
                        sigType = 'res';
                        polySignalCount++;
                        break;
                    }
                }
            }
            if (!sigType) {
                // Finally try generic matching
                if (label.toLowerCase().indexOf('eeg') > -1) {
                    sigType = 'eeg';
                    eegSignalCount++;
                }
                else {
                    const physUnit = fullHeader.getSignalPhysicalUnit(i)?.toLowerCase();
                    if (
                    // Physiologican units
                    physUnit === 'uv' || physUnit === 'mv' || physUnit === 'v' ||
                        // Physical units
                        physUnit === 'bpm' ||
                        // Mathematical units
                        physUnit === '%') {
                        // Generic signal
                        sigType = 'sig';
                    }
                }
            }
            if (!sigType) {
                // Finally try generic matching
                if (label.toLowerCase().indexOf('meg') > -1) {
                    sigType = 'meg';
                    megSignalCount++;
                }
                else {
                    const physUnit = fullHeader.getSignalPhysicalUnit(i)?.toLowerCase();
                    if (
                    // Physiologican units
                    physUnit === 'uv' || physUnit === 'mv' || physUnit === 'v' ||
                        // Physical units
                        physUnit === 'bpm' ||
                        // Mathematical units
                        physUnit === '%') {
                        // Generic signal
                        sigType = 'sig';
                    }
                }
            }
            // Try to determine record start
            const sigData = {
                label: label,
                name: label,
                type: sigType,
                samplingRate: fullHeader.getSignalSamplingFrequency(i) || 0,
                amplification: amplification,
                sensitivity: 0,
                signal: new Float32Array(),
                unit: fullHeader.getSignalPhysicalUnit(i) || '',
                samplesPerRecord: fullHeader.getSignalNumberOfSamplesPerRecord(i) || 0,
                sampleCount: 0,
                physicalMin: fullHeader.getSignalPhysicalMin(i) || 0,
                physicalMax: fullHeader.getSignalPhysicalMax(i) || 0,
                filter: fullHeader.getSignalPrefiltering(i) || '',
                transducer: fullHeader.getSignalTransducerType(i) || '',
            };
            sigData.sampleCount = sigData.samplesPerRecord * totalRecords;
            if (sigData.label.toLowerCase().indexOf('eeg') !== -1) {
                eegSignalCount++;
            }
            else if (sigData.label.toLowerCase().indexOf('meg') !== -1) {
                megSignalCount++;
            }
            else if (sigData.label.toLowerCase().indexOf('ekg') !== -1 ||
                sigData.label.toLowerCase().indexOf('emg') !== -1 ||
                sigData.label.toLowerCase().indexOf('eog') !== -1 ||
                sigData.label.toLowerCase().indexOf('loc') !== -1 ||
                sigData.label.toLowerCase().indexOf('res') !== -1 ||
                sigData.label.toLowerCase().indexOf('roc') !== -1) {
                polySignalCount++;
            }
            // Check signal for validity
            signals.push(sigData);
        }
        // Try to deduct the study subtype from signal types
        if (megSignalCount > this._study.meta.nSignals / 2) {
            // Mostly MEG signals, probably a MEG recording
            subType = ':meg';
        }
        else if (eegSignalCount > this._study.meta.nSignals / 2) {
            // Mostly EEG signals, probably an EEG recording
            subType = ':eeg';
        }
        else {
            // Unknown, use EEG viewer
            subType = ':eeg';
        }
        this._study.meta.channels = signals;
        this._study.meta.recording = fullHeader;
        // Always overwrite study format and type with EDF/biosignal
        this._study.format = 'edf';
        this._study.type = 'sig' + subType;
        return 'sig' + subType;
    }
    async loadFile(source, config = {}) {
        const file = source.file || source;
        Log.debug(`Loading EDF from file ${file.webkitRelativePath}.`, SCOPE);
        const studyFile = {
            file: file,
            format: 'edf',
            mime: config?.mime || file.type || null,
            name: config?.name || file.name || '',
            type: '',
            url: config?.url || URL.createObjectURL(file),
        };
        try {
            // Load header part from the EDF file into the study
            const headers = new Headers();
            headers.set('range', 'bytes=0-255');
            const mainHeader = file.slice(0, 255);
            const header = await this._loadEdfHeader(await mainHeader.arrayBuffer(), config);
            const fullHeader = file.slice(256, (header.nSignals + 1) * 256 - 1);
            const fullType = await this._loadEdfSignals(await fullHeader.arrayBuffer(), config);
            studyFile.type = fullType;
        }
        catch (e) {
            // EDF parsing error
            Log.error("EDF header parsing error!", SCOPE, e);
            return null;
        }
        this._study.files.push(studyFile);
        return studyFile;
    }
    async loadFileUrl(source, config = {}) {
        const url = source.url || source;
        Log.debug(`Loading EDF from url ${url}.`, SCOPE);
        const studyFile = {
            file: null,
            format: 'edf',
            mime: config?.mime || null,
            name: config?.name || '',
            type: '',
            url: config?.url || url,
        };
        try {
            // Load header part from the EDF file into the study
            const headers = new Headers();
            headers.set('range', 'bytes=0-255');
            const mainHeader = await fetch(url, {
                headers: headers,
            });
            const header = await this._loadEdfHeader(await mainHeader.arrayBuffer(), config);
            // Load full header including signal info
            headers.set('range', `bytes=256-${(header.nSignals + 1) * 256 - 1}`);
            const fullHeader = await fetch(url, {
                headers: headers,
            });
            const fullType = await this._loadEdfSignals(await fullHeader.arrayBuffer(), config);
            studyFile.type = fullType;
        }
        catch (e) {
            // EDF parsing error
            Log.error("EDF header parsing error!", SCOPE, e);
            return null;
        }
        this._study.files.push(studyFile);
        return studyFile;
    }
}
