/**
 * EpiCurrents Viewer markdown file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import GenericFileLoader from '../lib/loaders/GenericFileLoader';
import { SynergyParser } from './parsers/SynergySingleStudyParser';
import { TextParser } from './parsers/TextParser';
import Log from 'scoped-ts-log';
const SCOPE = 'TextFileLoader';
export default class TextFileLoader extends GenericFileLoader {
    constructor() {
        super(SCOPE, [], ['.txt']);
    }
    async _parseFile(source, config = {}) {
        const parser = new TextParser();
        //parser.addChildParser(new SynergyFullVisitParser())
        parser.addChildParser(new SynergyParser());
        if (await parser.parseSource(source, this._study)) {
            if (this._study.type === 'sig:ncs') {
                this._study.scope = 'ncs';
                return 'sig:ncs';
            }
            else if (this._study.type === 'sig:emg') {
                this._study.scope = 'emg';
                return 'sig:emg';
            }
            return null;
        }
        else {
            return null;
        }
    }
    async loadFile(source, config = {}) {
        const file = source.file || source;
        Log.debug(`Adding a Markdown file ${file.webkitRelativePath}.`, SCOPE);
        if (await this._parseFile(await file.arrayBuffer())) {
            const studyFile = {
                file: file,
                format: 'text',
                mime: config?.mime || 'text/plain',
                name: config?.name || file.name || '',
                type: '',
                url: config?.url || URL.createObjectURL(file),
            };
            this._study.files.push(studyFile);
            return studyFile;
        }
        else {
            return null;
        }
    }
    async loadFileUrl(source, config = {}) {
        const url = source.url || source;
        Log.debug(`Attempting to decode text file.`, SCOPE);
        // Check if this is a signal data file
        return await fetch(url).then(response => response.arrayBuffer()).then(async (buffer) => {
            if (await this._parseFile(buffer)) {
                const studyFile = {
                    file: null,
                    format: 'text',
                    mime: config?.mime || 'text/plain',
                    name: config?.name || '',
                    type: '',
                    url: config?.url || url,
                };
                this._study.files.push(studyFile);
                return studyFile;
            }
            else {
                return null;
            }
        });
    }
}
