/**
 * EpiCurrents Viewer Pyodide loader.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
const SCOPE = 'PyodideLoader';
export class PyodideLoader {
    _callbacks = [];
    _loadedPackages = [];
    /**
     * Some scripts are run only once and kept in memory.
     * This property lists names of scripts that should not be run multiple
     * times and their loading state.
     */
    _scripts = {
        'eeg_filter_signal': {
            params: {},
            state: 'not_loaded',
        },
        'eeg_load_topomap': {
            params: {},
            state: 'not_loaded',
        },
    };
    _worker;
    constructor() {
        this._worker = new Worker(new URL("LIB/workers/PyodideWorker.ts", import.meta.url));
        this._worker.onmessage = (event) => {
            const { id, ...data } = event.data;
            const onSuccess = this._callbacks[id];
            delete this._callbacks[id];
            onSuccess(data);
        };
    }
    _runAsync = (() => {
        let id = 0; // identify a Promise
        return (action, context) => {
            // the id could be generated more carefully
            id = (id + 1) % Number.MAX_SAFE_INTEGER;
            return new Promise((onSuccess) => {
                this._callbacks[id] = onSuccess;
                this._worker.postMessage({
                    ...context,
                    action: action,
                    id,
                });
            });
        };
    })();
    async loadPackages(packages) {
        packages = packages.filter(pkg => (this._loadedPackages.indexOf(pkg) === -1));
        const response = await this._runAsync('load-packages', { packages: packages });
        if (response.success) {
            this._loadedPackages.push(...packages);
        }
        return response;
    }
    async runCode(code, params) {
        const response = await this._runAsync('run-code', { code: code, ...params });
        return response;
    }
    async runScript(script, params) {
        if (script in this._scripts) {
            if (this._scripts[script].state === 'loading' ||
                this._scripts[script].state === 'loaded') {
                Log.info(`Script ${script} is already loading or has been loaded.`, SCOPE);
                return {
                    success: true,
                };
            }
            this._scripts[script].params = { ...params };
            this._scripts[script].state = 'loading';
        }
        const response = await this._runAsync('run-script', { script: script, ...params });
        if (response.success) {
            if (script in this._scripts) {
                this._scripts[script].state = 'loaded';
            }
        }
        return response;
    }
    async setupContext(context) {
        // Load context-specific packages
        //if (context === 'eeg') {
        //    await this.loadPackages(['scipy', 'matplotlib', 'mne'])
        //}
    }
}
