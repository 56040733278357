/**
 * Synergy extension for the text file parser.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import { NUMERIC_ERROR_VALUE } from "LIB/util/constants";
import Log from 'scoped-ts-log';
import { TextParser } from "./TextParser";
import { concatFloat32Arrays } from "LIB/util/signal";
const SCOPE = 'SynergyParser';
export class SynergyParser extends TextParser {
    static newSegmentPrototype() {
        return {
            distance: NUMERIC_ERROR_VALUE,
            from: NUMERIC_ERROR_VALUE,
            label: '',
            showCV: false,
            to: NUMERIC_ERROR_VALUE,
        };
    }
    static newSitePrototype() {
        return {
            cursors: [],
            markers: [],
            number: 0,
            onset: {
                label: '',
                position: NUMERIC_ERROR_VALUE,
                value: 0,
            },
            peak: {
                label: '',
                position: NUMERIC_ERROR_VALUE,
                value: 0,
            },
            stim: '',
            record: '',
            samplingRate: 0,
            range: [],
            totalAmplitude: 0,
            type: '?',
            // Will contain an empty float 32 array if no site results are found
            signal: new Float32Array(),
            signalComponents: [],
        };
    }
    /**
     * Parse common data and trace fields.
     * @param study - StudyObject
     * @param muscle - EmgStudy muscle
     * @param fv - [field name, field value]
     * @returns true if field successfully parsed, false otherwise
     */
    static parseEmgField(study, muscle, fv) {
        const [field, value] = fv;
        switch (field) {
            default:
                // Test for sampling rate, use only if not already set
                const samplingRate = field.match(/Sampling Frequency\(([^\)]+)\)/);
                if (samplingRate && !muscle.samplingRate) {
                    muscle.samplingRate = parseFloat(value.replace(',', '.'));
                    if (samplingRate[1] === 'kHz') {
                        muscle.samplingRate *= 1000;
                    }
                    return true;
                }
                // Test for subsampled sampling rate and replace original sampling rate
                const subsamplingRate = field.match(/Subsampled\(([^\)]+)\)/);
                if (subsamplingRate) {
                    muscle.samplingRate = parseFloat(value.replace(',', '.'));
                    if (subsamplingRate[1] === 'kHz') {
                        muscle.samplingRate *= 1000;
                    }
                    return true;
                }
                // No match
                return false;
        }
    }
    /**
     * Parse common data and trace fields.
     * @param study - StudyObject
     * @param site - NerveConductionStudy site
     * @param fv - [field name, field value]
     * @returns true if field successfully parsed, false otherwise
     */
    static parseNcsField(study, site, fv) {
        const [field, value] = fv;
        switch (field) {
            case 'Algorithm':
                if (value === 'Motor') {
                    site.type = 'm';
                    if (study.type !== 'sig:ncs') {
                        // Plain data exports may need to be tagged here
                        study.type = 'sig:ncs';
                    }
                }
                else if (value === 'Sensory') {
                    site.type = 's';
                    if (study.type !== 'sig:ncs') {
                        study.type = 'sig:ncs';
                    }
                }
                return true;
            case 'Run Type':
                if (value === 'Stim Triggered') {
                    if (study.type !== 'sig:ncs') {
                        // Plain data exports may need to be tagged here
                        study.type = 'sig:ncs';
                    }
                }
                return true;
            case 'Trace Duration(ms)':
                if (site.signal.length || site.signalComponents.length) {
                    const nSamples = site.signal.length || site.signalComponents[0].length;
                    site.samplingRate = (1000 / parseFloat(value.replace(',', '.'))) * nSamples;
                }
                return true;
            default:
                // Test for sampling rate, use only if not already set
                const samplingRate = field.match(/Sampling Frequency\(([^\)]+)\)/);
                if (samplingRate && !site.samplingRate) {
                    site.samplingRate = parseFloat(value.replace(',', '.'));
                    if (samplingRate[1] === 'kHz') {
                        site.samplingRate *= 1000;
                    }
                    return true;
                }
                // Test for subsampled sampling rate and replace original sampling rate
                const subsamplingRate = field.match(/Subsampled\(([^\)]+)\)/);
                if (subsamplingRate) {
                    site.samplingRate = parseFloat(value.replace(',', '.'));
                    if (subsamplingRate[1] === 'kHz') {
                        site.samplingRate *= 1000;
                    }
                    return true;
                }
                // No match
                return false;
        }
    }
    /**
     * Parse common triggered signal data fields.
     * @param study - StudyObject
     * @param signal - TriggeredSignal
     * @param fv - [field name, field value]
     * @returns true if field successfully parsed, false otherwise
     */
    static parseTrgField(study, signal, fv) {
        const [field, value] = fv;
        switch (field) {
            case 'Algorithm':
                if (value === 'SFJ' && study.type !== 'sig:emg') {
                    study.type = 'sig:emg';
                }
                return true;
            case 'Run Type':
                if (value === 'Signal Triggered') {
                }
                return true;
            case 'Trace Duration(ms)':
                signal.duration = parseInt(fv[1]) / 1000;
                return true;
            default:
                // Test for sampling rate, use only if not already set
                const samplingRate = field.match(/Sampling Frequency\(([^\)]+)\)/);
                if (samplingRate && !signal.samplingRate) {
                    signal.samplingRate = parseFloat(value.replace(',', '.'));
                    if (samplingRate[1] === 'kHz') {
                        signal.samplingRate *= 1000;
                    }
                    return true;
                }
                // Test for subsampled sampling rate and replace original sampling rate
                const subsamplingRate = field.match(/Subsampled\(([^\)]+)\)/);
                if (subsamplingRate) {
                    signal.samplingRate = parseFloat(value.replace(',', '.'));
                    if (subsamplingRate[1] === 'kHz') {
                        signal.samplingRate *= 1000;
                    }
                    return true;
                }
                // No match
                return false;
        }
    }
    parseLines(lines, study) {
        if (!lines[0].startsWith(';') || !lines[1].startsWith('; Synergy v.')) {
            Log.error(`Source is incompatible with SynergyParser.`, SCOPE);
            return false;
        }
        const version = parseInt(lines[1].substring(12, 14));
        if (version !== 20 && version !== 22) {
            Log.error(`Synergy version is not supported (version ${version}, only 20 and 22 are supported)`, SCOPE);
            return false;
        }
        if (lines[4].split(' ')[4] !== 'v.1') {
            Log.error(`Incompatible "${lines[4].substring(2)}" (v.1 expected), aborting file loading.`, SCOPE);
            return false;
        }
        // Remove "artificial" new lines from the lines array
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].endsWith('/') && lines.length > i + 1) {
                lines.splice(i, 2, lines[i].replace('/', ',') + lines[i + 1]);
                i--;
            }
        }
        let test = 0;
        let anatomy = 0;
        let site = 0;
        let section = '';
        const emgData = {
            name: '',
            muscles: [],
        };
        const ncsData = {
            name: '',
            side: 0,
            test: '',
            type: '',
            nerves: [],
        };
        const trgData = {
            name: '',
            type: '',
            muscles: []
        };
        let newMuscle = null;
        /**
         * Generate a new muscle prototype into newMuscle
         * and add it to the end of the list of emgData muscles.
         */
        const startNewMuscle = () => {
            newMuscle = {
                name: '',
                side: 0,
                signal: null
            };
            emgData.muscles.push(newMuscle);
        };
        let newNerve = null;
        /**
         * Generate a new nerve prototype into newNerve
         * and add it to the end of the list of ncsData nerves.
         */
        const startNewNerve = () => {
            newNerve = {
                name: '',
                segments: [],
                sites: [],
            };
            ncsData.nerves.push(newNerve);
        };
        let newSegment = null;
        /**
         * Generate a new segment prototype into newSegment and add a
         * possible previous segment to the end of the list of
         * newNerve segments.
         */
        const startNewSegment = () => {
            if (!newNerve) {
                return;
            }
            if (newSegment?.from !== undefined && newSegment.to !== undefined) {
                newNerve.segments.push(newSegment);
            }
            newSegment = SynergyParser.newSegmentPrototype();
        };
        let newSite = null;
        /**
         * Generate a new site prototype into newSite and add a possible
         * previous site to the end of the list of newNerve sites.
         */
        const startNewSite = () => {
            if (!newNerve) {
                return;
            }
            // Add the previous site to nerve sites if it had data
            if (newSite?.signal.length) {
                newNerve.sites.push(newSite);
            }
            newSite = SynergyParser.newSitePrototype();
        };
        let newTrgMuscle = null;
        /**
         * Generate a new triggered muscle prototype into newTrgMuscle
         * and add a possible previous to the end of the list of
         * trgData muscles.
         */
        const startNewTrgMuscle = () => {
            newTrgMuscle = {
                name: '',
                side: 0,
                signals: [],
            };
            trgData.muscles.push(newTrgMuscle);
        };
        let newTrgSignal = null;
        /**
         * Generate a new triggered signal prototype into newTrgSignal
         * and add it to the end of the list of newTrgMuscle signals.
         */
        const startNewTrgSignal = () => {
            if (!newTrgMuscle) {
                return;
            }
            newTrgSignal = {
                data: new Float32Array(),
                duration: 0,
                samplingRate: 0,
            };
            newTrgMuscle.signals.push(newTrgSignal);
        };
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            const newSection = line.match(/\[((\d+\.)*\d+) - ([^\]]+)\]/);
            if (newSection) {
                // Determine the new data section
                const sectionNums = newSection[1].split('.').map(num => parseInt(num));
                if (sectionNums[0] !== test) {
                    startNewNerve();
                    test = sectionNums[0];
                    anatomy = 0;
                    section = '';
                }
                // This part differs a bit between the two versions
                if (version === 20 && sectionNums[1] && sectionNums[1] !== anatomy) {
                    startNewSite();
                    anatomy = sectionNums[1];
                }
                else if (version === 22 && sectionNums[2] && sectionNums[2] !== anatomy) {
                    startNewSite();
                    anatomy = sectionNums[2];
                }
                if (newSection[3] === 'LivePlay Data') {
                    section = newSection[3];
                }
                else if (newSection[3] === 'Test Details') {
                    section = newSection[3];
                }
                else if (newSection[3] === 'Anatomy') {
                    // Declare new section only if checks pass
                    section = newSection[3];
                    continue;
                }
                else if (newSection[3] === 'Data Group') {
                    section = newSection[3];
                    continue;
                }
                else if (newNerve &&
                    newSection[3].endsWith('Results')) {
                    // Site or segment results
                    if (newSection[3].endsWith('Segment Results')) {
                        // New nerve segment
                        startNewSegment();
                    }
                    else {
                        if (newSection[3].startsWith('FWave') && newSite) {
                            newSite.type = 'f';
                        }
                        else if (newSection[3].startsWith('HReflex') && newSite) {
                            newSite.type = 'h';
                        }
                        if (study.type === '' && (newSection[3].startsWith('FWave ') ||
                            newSection[3].startsWith('HReflex') ||
                            newSection[3].startsWith('NCV '))) {
                            study.type = 'sig:ncs';
                        }
                    }
                    section = 'Results';
                    continue;
                }
                else if (
                // There are two ways we can encounter store data:
                // - As a part of detailed test results export.
                // - As plain data store export without test details.
                // Handle detailed results export
                newSection[3] === 'Store Data') {
                    section = newSection[3];
                    if (newTrgMuscle) {
                    }
                    else {
                        if (!newNerve) {
                            // Plain store data export
                            ncsData.name = 'Unknown';
                            startNewNerve();
                        }
                        if (!newSite) {
                            startNewSite();
                        }
                    }
                    continue;
                }
                else if (newSection[3] === 'Trace Data') {
                    section = newSection[3];
                    if (newTrgMuscle) {
                        startNewTrgSignal();
                    }
                    else {
                        if (!newNerve) {
                            // Plain trace data export
                            ncsData.name = 'Unknown';
                            startNewNerve();
                        }
                        if (!newSite) {
                            // Plain trace store export
                            startNewSite();
                        }
                    }
                    continue;
                }
                else {
                    // Unknown section or some check failed
                    section = '';
                }
            }
            else if (line.length) {
                // Parse data fields within a section
                const [field, value] = line.split('=');
                if (section === 'Test Details') {
                    switch (field) {
                        case 'Full Name':
                            if (newMuscle) {
                                newMuscle.name = value;
                            }
                            else if (newTrgMuscle) {
                                newTrgMuscle.name = value;
                            }
                            else {
                                ncsData.name = value;
                            }
                            break;
                        case 'Master Anatomy':
                            if (newTrgMuscle) {
                                newTrgMuscle.name = value;
                            }
                            break;
                        case 'Side':
                            if (newMuscle) {
                                newMuscle.side = parseInt(value);
                            }
                            else if (newTrgMuscle) {
                                newTrgMuscle.side = parseInt(value);
                            }
                            else {
                                ncsData.side = parseInt(value);
                            }
                            break;
                        case 'Test':
                            if (value === 'SFEMG Jitter') {
                                trgData.type = 'j';
                                startNewTrgMuscle();
                            }
                            else {
                                ncsData.test = value;
                            }
                            break;
                    }
                }
                else if (section === 'Anatomy') {
                    switch (field) {
                        case 'Master Anatomy':
                            if (newMuscle) {
                                newMuscle.name = value;
                            }
                            else if (newTrgMuscle) {
                                newTrgMuscle.name = value;
                            }
                            break;
                    }
                }
                else if (section === 'Results' && newSegment) {
                    // Distances can be in cm or mm, multiply accordingly.
                    // TODO: Imperial units?
                    const dMultiplier = field.endsWith('(cm)') ? 10 : 1;
                    if (field.startsWith('Segment From Site')) {
                        newSegment.from = parseInt(value);
                    }
                    else if (field.startsWith('Segment To Site')) {
                        newSegment.to = parseInt(value);
                    }
                    else if (field.startsWith('Distance(')) {
                        newSegment.distance = parseFloat(value.replace(',', '.')) * dMultiplier;
                    }
                }
                else if (section === 'Results' && newSite) {
                    // Amplitudes can be in µV or mV; in the latter case, multiply value with 1000
                    const vMultiplier = field.endsWith('(mV)') ? 1000 : 1;
                    if (newSite.type === 'f' || newSite.type === 'h') {
                        // Try F-wave specific data fields
                        const cursorLat = field.match(/Cursor (\d+) Latency\(ms\)/);
                        if (cursorLat) {
                            const lat = parseFloat(value.replace(',', '.'));
                            newSite.cursors.push({
                                label: field.replace('(ms)', ''),
                                position: lat,
                                value: lat,
                            });
                            continue;
                        }
                        const cursorLbl = field.match(/Cursor (\d+) Label/);
                        if (cursorLbl && newSite.cursors[parseInt(cursorLbl[1]) - 1]) {
                            newSite.cursors[parseInt(cursorLbl[1]) - 1].label = value;
                            continue;
                        }
                    }
                    switch (field.indexOf('(') > 0 ? field.substring(0, field.indexOf('(')) : field) {
                        // Sensory and motor nerve results
                        case 'Stim site':
                            newSite.number = parseInt(value);
                            break;
                        case 'Stim Site Label':
                            newSite.stim = value;
                            break;
                        case 'Recording Site Label':
                            newSite.record = value;
                            break;
                        case 'Take off Latency':
                            // Latencies are always in ms
                            newSite.onset.position = parseFloat(value.replace(',', '.'));
                            break;
                        case 'Peak Latency':
                            newSite.peak.position = parseFloat(value.replace(',', '.'));
                            break;
                        case 'Peak Amplitude':
                            const peak = parseFloat(value.replace(',', '.')) * vMultiplier;
                            newSite.range = peak < 0 ? [peak, 0] : [0, peak];
                            newSite.peak.value = peak;
                            break;
                        case 'Peak-to-Peak Amplitude':
                            const p2p = parseFloat(value.replace(',', '.')) * vMultiplier;
                            if (!newSite.range[0]) {
                                newSite.range[0] = newSite.range[1] - p2p;
                            }
                            else {
                                newSite.range[1] = newSite.range[0] + p2p;
                            }
                            newSite.totalAmplitude = p2p;
                            break;
                        default:
                            // Check for marker latency
                            const markerLat = field.match(/Marker (\d+) Latency\(ms\)/);
                            if (markerLat) {
                                newSite.markers[parseInt(markerLat[1]) - 1] = {
                                    label: '',
                                    position: parseFloat(value.replace(',', '.')),
                                    value: 0
                                };
                                break;
                            }
                    }
                }
                else if (section === 'LivePlay Data' && newMuscle) {
                    if (!SynergyParser.parseEmgField(study, newMuscle, [field, value])) {
                        // Test for live play data
                        const livePlay = field.match(/LivePlay Data\(([^\)]+)\)<(\d+)>/);
                        if (livePlay) {
                            if (!study.type) {
                                // Live play usually means an EMG study
                                study.type = 'sig:emg';
                            }
                            const multiplier = livePlay[1] === 'nV' ? 0.001
                                : livePlay[1] === 'µV' ? 1
                                    : livePlay[1] === 'mV' ? 1000
                                        : 1;
                            const nSamples = parseInt(livePlay[2]);
                            const samples = value.split(',');
                            let data = [];
                            if (samples.length === nSamples) {
                                // Samples are integers or fractions with dot delimiter
                                data = samples.map(dataPoint => parseFloat(dataPoint) * multiplier);
                            }
                            else if (samples.length === 2 * nSamples) {
                                // Samples are fractions with comma delimiter
                                for (let i = 0; i < nSamples; i++) {
                                    data.push(parseFloat(`${samples[i * 2]}.${samples[i * 2 + 1]}`) * multiplier);
                                }
                            }
                            else {
                                // Something else?
                                Log.error(`${emgData.name} ${newMuscle?.name} muscle number of live play data points (${samples.length}) did not match expected number of samples ${nSamples}.`, SCOPE);
                            }
                            if (!newMuscle.signal) {
                                newMuscle.signal = new Float32Array(data);
                            }
                            else {
                                newMuscle.signal = concatFloat32Arrays(newMuscle.signal, new Float32Array(data));
                            }
                        }
                    }
                }
                else if (section === 'Store Data') {
                    if (newSite && !SynergyParser.parseNcsField(study, newSite, [field, value])) {
                        // Test for averaged signal data
                        const signalData = field.match(/Averaged Data\((..)\)<(\d+)>/);
                        if (signalData) {
                            const multiplier = signalData[1] === 'nV' ? 0.001
                                : signalData[1] === 'µV' ? 1
                                    : signalData[1] === 'mV' ? 1000
                                        : 1;
                            const nSamples = parseInt(signalData[2]);
                            const samples = value.split(',');
                            let data = [];
                            if (samples.length === nSamples) {
                                // Samples are integers or fractions with dot delimiter
                                data = samples.map(dataPoint => parseFloat(dataPoint) * multiplier);
                            }
                            else if (samples.length === 2 * nSamples) {
                                // Samples are fractions with comma delimiter
                                for (let i = 0; i < nSamples; i++) {
                                    data.push(parseFloat(`${samples[i * 2]}.${samples[i * 2 + 1]}`) * multiplier);
                                }
                            }
                            else {
                                // Something else?
                                Log.error(`${ncsData.name} ${newNerve?.name} site ${newSite.stim}-${newSite.record} number of data points (${samples.length}) did not match expected number of samples ${nSamples}.`, SCOPE);
                            }
                            // Check that current site doesn't already have signal data
                            // (this can happen in exports of store data without results).
                            if (newSite.signal.length && newNerve) {
                                startNewSite();
                            }
                            newSite.signal = new Float32Array(data);
                            continue;
                        }
                    }
                    else if (newTrgSignal && !SynergyParser.parseTrgField(study, newTrgSignal, [field, value])) {
                        // Test for averaged signal data (not yet used for anything...)
                        const signalData = field.match(/Averaged Data\((..)\)<(\d+)>/);
                        if (signalData) {
                            const multiplier = signalData[1] === 'nV' ? 0.001
                                : signalData[1] === 'µV' ? 1
                                    : signalData[1] === 'mV' ? 1000
                                        : 1;
                            const nSamples = parseInt(signalData[2]);
                            const samples = value.split(',');
                            let data = [];
                            if (samples.length === nSamples) {
                                // Samples are integers or fractions with dot delimiter
                                data = samples.map(dataPoint => parseFloat(dataPoint) * multiplier);
                            }
                            else if (samples.length === 2 * nSamples) {
                                // Samples are fractions with comma delimiter
                                for (let i = 0; i < nSamples; i++) {
                                    data.push(parseFloat(`${samples[i * 2]}.${samples[i * 2 + 1]}`) * multiplier);
                                }
                            }
                            else {
                                // Something else?
                                Log.error(`${trgData.name} ${newTrgMuscle?.name} muscle number of data points (${samples.length}) did not match expected number of samples ${nSamples}.`, SCOPE);
                            }
                            continue;
                        }
                    }
                }
                else if (section === 'Trace Data') {
                    if (newSite && !SynergyParser.parseNcsField(study, newSite, [field, value])) {
                        // Test for raw sweep data (for some reason there are two spaces there)
                        const signalData = field.match(/Sweep  Data\((..)\)<(\d+)>/);
                        if (signalData) {
                            const multiplier = signalData[1] === 'nV' ? 1000
                                : signalData[1] === 'µV' ? 1
                                    : signalData[1] === 'mV' ? 0.001
                                        : 1;
                            const nSamples = parseInt(signalData[2]);
                            const samples = value.split(',');
                            let data = [];
                            if (samples.length === nSamples) {
                                // Samples are integers or fractions with dot delimiter
                                data = samples.map(dataPoint => parseFloat(dataPoint) * multiplier);
                            }
                            else if (samples.length === 2 * nSamples) {
                                // Samples are fractions with comma delimiter
                                for (let i = 0; i < nSamples; i++) {
                                    data.push(parseFloat(`${samples[i * 2]}.${samples[i * 2 + 1]}`) * multiplier);
                                }
                            }
                            else {
                                // Something else?
                                Log.error(`${ncsData.name} ${newNerve?.name} site ${newSite.stim}-${newSite.record} sweep number of data points (${samples.length}) did not match expected number of samples ${nSamples}.`, SCOPE);
                            }
                            newSite.signalComponents.push(new Float32Array(data));
                        }
                    }
                    else if (newTrgSignal && !SynergyParser.parseTrgField(study, newTrgSignal, [field, value])) {
                        // Test for sweep signal data
                        const sweepData = field.match(/Sweep  Data\((..)\)<(\d+)>/);
                        if (sweepData) {
                            const multiplier = sweepData[1] === 'nV' ? 0.001
                                : sweepData[1] === 'µV' ? 1
                                    : sweepData[1] === 'mV' ? 1000
                                        : 1;
                            const nSamples = parseInt(sweepData[2]);
                            const samples = value.split(',');
                            let data = [];
                            if (samples.length === nSamples) {
                                // Samples are integers or fractions with dot delimiter
                                data = samples.map(dataPoint => parseFloat(dataPoint) * multiplier);
                            }
                            else if (samples.length === 2 * nSamples) {
                                // Samples are fractions with comma delimiter
                                for (let i = 0; i < nSamples; i++) {
                                    data.push(parseFloat(`${samples[i * 2]}.${samples[i * 2 + 1]}`) * multiplier);
                                }
                            }
                            else {
                                // Something else?
                                Log.error(`${trgData.name} ${newTrgMuscle?.name} muscle number of data points (${samples.length}) did not match expected number of samples ${nSamples}.`, SCOPE);
                            }
                            newTrgSignal.data = new Float32Array(data);
                            continue;
                        }
                    }
                }
            }
            else {
                // Empty line marks the end of a section
                section = '';
                newMuscle = null;
                newTrgSignal = null;
            }
        }
        // Save the last site and segment
        startNewSite();
        startNewSegment();
        for (const muscle of emgData.muscles) {
            if (muscle?.signal) {
                // Save duration into previous muscle object.
                // This can only be done afterwards, after all the signal segments
                // have been combined.
                muscle.duration = muscle.signal.length / muscle.samplingRate;
            }
        }
        for (const nerve of ncsData.nerves) {
            for (const seg of nerve.segments) {
                let from = '';
                let to = '';
                for (const site of nerve.sites) {
                    if (site.number === seg.from) {
                        from = site.stim;
                        if (site.type === 's') {
                            // Always show conduction velocity for sensory nerves
                            seg.showCV = true;
                        }
                    }
                    else if (site.number === seg.to) {
                        to = site.stim;
                        if (site.type === 'm' || site.type === 's') {
                            // Show conduction velocity for motor nerves that have
                            // a 'to' site specified.
                            seg.showCV = true;
                        }
                    }
                }
                if (from) {
                    seg.label = from;
                }
                if (to) {
                    if (from) {
                        seg.label += ` - ${to}`;
                    }
                    else {
                        seg.label = `- ${to}`;
                    }
                }
            }
            for (const site of nerve.sites) {
                // Add onset and peak amplitudes if needed
                if (site.onset.position !== NUMERIC_ERROR_VALUE) {
                    // Check that there is actual signal data at the position
                    const sigIndex = Math.round(site.samplingRate * site.onset.position / 1000);
                    if (site.signal && site.signal[sigIndex] !== undefined) {
                        site.onset.value = site.signal[sigIndex];
                    }
                }
                if (site.peak.position !== NUMERIC_ERROR_VALUE) {
                    const sigIndex = Math.round(site.samplingRate * site.peak.position / 1000);
                    if (site.signal && site.signal[sigIndex] !== undefined) {
                        site.peak.value = site.signal[sigIndex];
                    }
                }
                // Check if we need to add marker values
                for (let i = 0; i < site.markers.length; i++) {
                    const mark = site.markers[i];
                    const markIndex = Math.round(site.samplingRate * mark.position / 1000);
                    if (site.signal && site.signal[markIndex] !== undefined) {
                        mark.value = site.signal[markIndex];
                    }
                }
                if (site.signalComponents.length) {
                    // Synergy exports signal traces in reversed order for some reason
                    site.signalComponents.reverse();
                }
            }
        }
        for (const muscle of trgData.muscles) {
            if (muscle.signals.length) {
                // Reversed signal traces in export
                muscle.signals.reverse();
            }
        }
        if (emgData.muscles.length) {
            study.data = emgData;
        }
        else if (ncsData.nerves.length) {
            study.data = ncsData;
        }
        else if (trgData.muscles.length) {
            study.data = trgData;
            // This is just for development testing
            study.type = 'sig:ncs';
            study.data.nerves = study.data.muscles;
            for (const nerve of study.data.nerves) {
                startNewNerve();
                startNewSite();
                nerve.sites = [newSite];
                nerve.segments = [];
                newSite.samplingRate = nerve.signals[0].samplingRate;
                newSite.signalComponents = nerve.signals.map((s) => s.data);
            }
        }
        return true;
    }
}
