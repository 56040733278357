/**
 * Generic biosignal recording headers.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
const SCOPE = 'GenericBiosignalHeaders';
export default class GenericBiosignalHeaders {
    _annotations;
    _dataGaps;
    _dataRecordCount;
    _dataRecordDuration;
    _dataRecordSize;
    _fileType;
    _maxSamplingRate = 0;
    _patientId;
    _recordingId;
    _recordingStartDate = null;
    _signalCount;
    _signalProperties = [];
    constructor(fileType, recordingId, patientId, dataRecordCount, dataRecordDuration, dataRecordSize, signalCount, signalProperties, recordingStartDate = null, annotations = [], dataGaps = new Map()) {
        this._annotations = annotations;
        this._dataGaps = dataGaps;
        this._dataRecordDuration = dataRecordDuration;
        this._dataRecordSize = dataRecordSize;
        this._fileType = fileType;
        this._patientId = patientId;
        this._dataRecordCount = dataRecordCount;
        this._recordingId = recordingId;
        this._recordingStartDate = recordingStartDate;
        this._signalCount = signalCount;
        this._signalProperties = signalProperties;
    }
    get annotations() {
        return this._annotations;
    }
    get dataGaps() {
        return this._dataGaps;
    }
    get fileType() {
        return this._fileType;
    }
    /**
     * Maximum signal sampling rate in recording.
     */
    get maxSamplingRate() {
        return this._maxSamplingRate;
    }
    /**
    * Number of records per signal.
    */
    get dataRecordCount() {
        return this._dataRecordCount;
    }
    /**
    * Duration of a single record in seconds.
    */
    get dataRecordDuration() {
        return this._dataRecordDuration;
    }
    /**
     * Total bytesize of a single data record.
     */
    get dataRecordSize() {
        return this._dataRecordSize;
    }
    /**
    * The patient ID.
    */
    get patientId() {
        return this._patientId;
    }
    /**
    * ID of the recording.
    */
    get recordingId() {
        return this._recordingId;
    }
    /**
    * Date and the time at which the recording has started (if known).
    */
    get recordingStartDate() {
        return this._recordingStartDate;
    }
    /**
    * Number of signals in the file (and in each record).
    */
    get signalCount() {
        return this._signalCount || this._signalProperties.length;
    }
    get signals() {
        return this._signalProperties;
    }
    /**
     * Get the total duration of this recording.
     * @returns total duration in seconds
     */
    get totalDuration() {
        return this._dataRecordCount * this._dataRecordDuration;
    }
    addAnnotations(...items) {
        this._annotations.push(...items);
    }
    addDataGaps(items) {
        for (const gap of items) {
            this._dataGaps.set(gap[0], gap[1]);
        }
    }
    /**
    * Get the label for a given signal index.
    * @param index - index of the signal
    * @return signal label, null if signal index out of range
    */
    getSignalLabel(index) {
        if (index < 0 || index >= this._signalProperties.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return signal label.`, SCOPE);
            return null;
        }
        return this._signalProperties[index].label;
    }
    /**
    * Get the number of samples per record for a given signal index.
    * @param index - index of the signal
    * @return samples per record, null if signal index out of range
    */
    getSignalNumberOfSamplesPerRecord(index) {
        if (index < 0 || index >= this._signalProperties.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return number of samples per record.`, SCOPE);
            return null;
        }
        return this._signalProperties[index].sampleCount;
    }
    /**
    * Get the unit (dimension label) used for a given signal index.
    * E.g. this can be 'uV' when the signal is an EEG.
    * @param index - index of the signal
    * @return the unit name, null if signal index out of range
    */
    getSignalPhysicalUnit(index) {
        if (index < 0 || index >= this._signalProperties.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return physical signal unit.`, SCOPE);
            return null;
        }
        return this._signalProperties[index].physicalUnit;
    }
    /**
    * Get the sampling frequency in Hz of a given signal
    * @param index - index of the signal
    * @return frequency in Hz, null if signal index out of range
    */
    getSignalSamplingFrequency(index) {
        if (index < 0 || index >= this._signalProperties.length) {
            Log.warn(`Signal index ${index} is out of range, cannot return signal sampling frequency.`, SCOPE);
            return null;
        }
        if (!this._dataRecordDuration) {
            Log.warn(`Signal index ${index} has a data record duration of zero, cannot determine sampling frequency.`, SCOPE);
            return null;
        }
        return this._signalProperties[index].sampleCount / this._dataRecordDuration;
    }
}
