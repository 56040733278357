/**
 * EpiCurrents Viewer Vuex store NCS module.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const ncs = {
    moduleName: {
        code: 'ncs',
        full: 'Nerve conduction studies',
        short: 'NCS',
    },
    resources: [],
};
const getters = {
    activeNcsResource: (state) => () => {
        for (const resource of ncs.resources) {
            if (resource.isActive) {
                return resource;
            }
        }
        return null;
    }
};
export { ncs, getters };
