/**
 * EpiCurrents Viewer generic media RESOURCE.
 * This class serves only as as superclass for more spesific media classes.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import SETTINGS from "CONFIG/Settings";
import LoaderMemoryManager from "LIB/loaders/LoaderMemoryManager";
import { nullPromise } from "LIB/util/general";
import { shouldDisplayChannel } from "LIB/util/montage";
import Log from 'scoped-ts-log';
import GenericMediaResource from "./GenericMediaResource";
const SCOPE = 'GenericBiosignalResource';
export default class GenericBiosignalResource extends GenericMediaResource {
    static MEMORY_MANAGER = new LoaderMemoryManager(SETTINGS.app.maxLoadCacheSize);
    _activeMontage = null;
    _annotations = [];
    _channels = [];
    _cursors = [];
    _dataDuration = 0;
    _dataGaps = new Map();
    _displayViewStart = 0;
    _filters = {
        highpass: 0,
        lowpass: 0,
        notch: 0,
    };
    _loaded = false;
    _loader = null;
    _montages = [];
    _recordMontage = null;
    _sampleCount = null;
    _samplingRate = null;
    _sensitivity;
    _setup = null;
    _signalCacheStatus = [0, 0];
    _startTime = null;
    _totalDuration = 0;
    _url = '';
    _videos = [];
    _viewStart = 0;
    constructor(name, sensitivity, type, url) {
        super(name, GenericMediaResource.CONTEXTS.BIOSIGNAL, type);
        if (url) {
            this._url = url;
        }
        this._sensitivity = sensitivity;
        // Set default filters
        this._filters.highpass = SETTINGS.eeg.filters.highpass.default || 0;
        this._filters.lowpass = SETTINGS.eeg.filters.lowpass.default || 0;
        this._filters.notch = SETTINGS.eeg.filters.notch.default || 0;
    }
    get activeMontage() {
        return this._activeMontage;
    }
    get annotations() {
        return this._annotations;
    }
    set annotations(annotations) {
        // Sort the annotations in ascending order according to start time
        annotations.sort((a, b) => a.start - b.start);
        this._annotations = annotations;
        this.onPropertyUpdate('annotations');
    }
    get channels() {
        return this._channels;
    }
    get cursors() {
        return this._cursors;
    }
    get dataDuration() {
        return this._dataDuration;
    }
    set dataDuration(duration) {
        this._dataDuration = duration;
    }
    get dataGaps() {
        return this._dataGaps;
    }
    set dataGaps(gaps) {
        this._dataGaps = gaps;
        this.onPropertyUpdate('data-gaps');
        // Set updated data gaps in montages
        for (const montage of this._montages) {
            montage.dataGaps = gaps;
        }
    }
    get displayViewStart() {
        return this._displayViewStart;
    }
    set displayViewStart(value) {
        this._displayViewStart = value;
        this.onPropertyUpdate('display-view-start');
    }
    get filters() {
        return this._filters;
    }
    get hasVideo() {
        return (this._videos.length > 0);
    }
    get id() {
        return this._id;
    }
    get isLoaded() {
        return this._loaded;
    }
    set isLoaded(value) {
        this._loaded = value;
        this.onPropertyUpdate('is-loaded');
    }
    get loader() {
        return this._loader;
    }
    get maxSampleCount() {
        return Math.max(0, ...this._channels.filter(chan => shouldDisplayChannel(chan, true)).map(chan => chan.sampleCount));
    }
    get maxSamplingRate() {
        return Math.max(0, ...this._channels.filter(chan => shouldDisplayChannel(chan, true)).map(chan => chan.samplingRate));
    }
    get montages() {
        return this._montages;
    }
    set montages(montages) {
        this._montages = montages;
        this.onPropertyUpdate('montages');
    }
    get name() {
        return this._name;
    }
    get recordMontage() {
        return this._recordMontage;
    }
    set recordMontage(montage) {
        this._recordMontage = montage;
    }
    get sampleCount() {
        return this._sampleCount;
    }
    get samplingRate() {
        return this._samplingRate;
    }
    get sensitivity() {
        return this._sensitivity;
    }
    set sensitivity(value) {
        if (value <= 0) {
            Log.error(`Sensitivity must be greater than zero, ${value} was given.`, SCOPE);
            return;
        }
        this._sensitivity = value;
        this.onPropertyUpdate('sensitivity');
    }
    get setup() {
        return this._setup;
    }
    set setup(setup) {
        this._setup = setup;
        this.onPropertyUpdate('setup');
    }
    get signalCacheStatus() {
        return this._signalCacheStatus;
    }
    set signalCacheStatus(status) {
        if (status.length !== 2) {
            Log.error(`Signal cache status must be a numeric array with length of 2 (array with length of ${status.length} given).`, SCOPE);
            return;
        }
        this._signalCacheStatus = status;
        this.onPropertyUpdate('signal-cache-status');
    }
    get startTime() {
        return this._startTime;
    }
    get totalDuration() {
        return this._totalDuration;
    }
    set totalDuration(duration) {
        this._totalDuration = duration;
    }
    get type() {
        return this._type;
    }
    get url() {
        return this._url;
    }
    get videos() {
        return this._videos;
    }
    set videos(videos) {
        this._videos = videos;
    }
    get viewStart() {
        return this._viewStart;
    }
    set viewStart(start) {
        if (start < 0) {
            start = 0;
        }
        this._viewStart = start;
        this.onPropertyUpdate('view-start');
    }
    get visibleChannels() {
        return this._channels.filter(c => shouldDisplayChannel(c, true));
    }
    ///////////////////////////////////////////////////
    //                   METHODS                     //
    ///////////////////////////////////////////////////
    addAnnotations(...annotations) {
        new_loop: for (const newAnno of annotations) {
            for (const oldAnno of this._annotations) {
                if (oldAnno.id === newAnno.id ||
                    (oldAnno.start === newAnno.start &&
                        oldAnno.duration === newAnno.duration &&
                        oldAnno.type === newAnno.type &&
                        oldAnno.label === newAnno.label &&
                        oldAnno.channels.length === newAnno.channels.length &&
                        oldAnno.channels.every(val => newAnno.channels.includes(val)))) {
                    continue new_loop;
                }
            }
            this._annotations.push(newAnno);
        }
        this._annotations.sort((a, b) => a.start - b.start);
    }
    addCursors(...cursors) {
        for (const curs of cursors) {
            this._cursors.push(curs);
        }
    }
    deleteAnnotations(...ids) {
        let idxOffset = 0;
        for (const id of ids) {
            if (typeof id === 'number' && id >= 0 && id - idxOffset < this._annotations.length) {
                this._annotations.splice(id - idxOffset, 1);
                idxOffset++;
            }
            else if (typeof id === 'string') {
                for (let i = 0; i < this._annotations.length; i++) {
                    if (this._annotations[i].id === id) {
                        this._annotations.splice(i, 1);
                        break;
                    }
                }
            }
        }
        this.onPropertyUpdate('annotations');
    }
    getAllSignals(range, config) {
        if (!this._activeMontage) {
            return this.getAllRawSignals(range, config);
        }
        return this._activeMontage.getAllSignals(range, config).then((response) => {
            return response;
        });
    }
    /**
     * Get raw signals from all channels for the given range.
     * @param range signal range in seconds [start (included), end (excluded)]
     * @param config optional config
     * @returns signals in range as Float32Array[]
     */
    getAllRawSignals(range, config) {
        return this._loader?.loadSignals(range, config) || nullPromise;
    }
    getChannelAtYPosition(yPos) {
        // Check for invalid position
        if (yPos < 0 || yPos > 1) {
            return null;
        }
        // Try to identify the channel at given position
        const visibleChannels = this._activeMontage?.visibleChannels || this.visibleChannels;
        if (!visibleChannels.length) {
            return null;
        }
        for (let i = 0; i < visibleChannels.length; i++) {
            const offset = visibleChannels[i]?.offset;
            if (offset !== undefined && offset.bottom <= yPos && offset.top >= yPos) {
                const chanIndex = i;
                return {
                    index: chanIndex,
                    top: offset.top,
                    bottom: offset.bottom,
                };
            }
        }
        return null;
    }
    getChannelSignal(channel, range, config) {
        if (!this._activeMontage) {
            return this.getRawChannelSignal(channel, range, config);
        }
        return this._activeMontage.getChannelSignal(channel, range, config).then((response) => {
            return response;
        });
    }
    getRawChannelSignal(channel, range, config) {
        if (!config) {
            // Initialize config
            config = { include: [] };
        }
        if (typeof channel === 'string') {
            for (let i = 0; i < this._channels.length; i++) {
                if (this._channels[i]?.name === channel) {
                    config.include = [i];
                    break;
                }
                else if (i === this._channels.length - 1) {
                    // Did not find the requested channel, return empty array
                    return new Promise((resolve) => {
                        resolve({ signals: [], range: [] });
                    });
                }
            }
        }
        return this._loader?.loadSignals(range, config) || nullPromise;
    }
    async releaseBuffers() {
        Log.info(`Releasing data buffers in ${this.name}.`, SCOPE);
        for (const mtg of this._montages) {
            await mtg.releaseBuffers();
        }
        Log.info(`Montage buffers released.`, SCOPE);
        this._montages.splice(0);
        this._montages = [];
        await this.loader?.unload();
        Log.info(`Signal loader buffers released.`, SCOPE);
        this.signalCacheStatus.splice(0);
        this.signalCacheStatus = [0, 0];
    }
    removeAllPropertyUpdateHandlers() {
        for (const chan of this._channels) {
            chan.removeAllPropertyUpdateHandlers();
        }
        super.removeAllPropertyUpdateHandlers();
    }
    async setActiveMontage(montage) {
        this._activeMontage?.removeAllPropertyUpdateHandlers();
        if (montage === null) {
            // Use raw signals
            if (this._activeMontage) {
                this._activeMontage.stopCachingSignals();
            }
            this._activeMontage = null;
            this.onPropertyUpdate('active-montage');
            return;
        }
        if (typeof montage === 'string') {
            // Match montage name to montage index
            for (let i = 0; i < this._montages.length; i++) {
                if (this._montages[i].name === montage) {
                    montage = i;
                    break;
                }
                else if (i === this._montages.length - 1) {
                    // No match found
                    return;
                }
            }
        }
        if (montage >= 0 && montage < this._montages.length) {
            if (this._activeMontage?.name !== this._montages[montage].name) {
                this._activeMontage?.stopCachingSignals();
                this._activeMontage = this._montages[montage];
                // Relay channel updates to the resource listeners
                this._activeMontage.addPropertyUpdateHandler('channels', () => {
                    this.activeMontage?.updateFilters();
                    this.onPropertyUpdate('channels');
                });
                // Update filter settings in case they have changed since this montage was created/active
                await this._activeMontage.updateFilters();
                this.onPropertyUpdate('active-montage');
            }
        }
    }
    setDefaultSensitivity(value) {
        this.sensitivity = value;
    }
    setHighpassFilter(value, target = 'eeg', scope = 'recording') {
        if (value === null) {
            value = 0;
        }
        else if (value < 0 || value === this._filters.highpass) {
            return;
        }
        if (typeof target === 'number' && this._activeMontage) {
            // Channel index can only refer to montage channels
            this._activeMontage.setHighpassFilter(target, value);
        }
        else if (typeof target === 'string') {
            if (scope === 'recording') {
                if (target === 'eeg') {
                    this._filters.highpass = value;
                }
            }
            else if (this._activeMontage) {
                this._activeMontage.setHighpassFilter(target, value);
            }
        }
        this._activeMontage?.updateFilters();
        this.onPropertyUpdate('highpass-filter');
    }
    setLowpassFilter(value, target = 'eeg', scope = 'recording') {
        if (value === null) {
            value = 0;
        }
        else if (value < 0 || value === this._filters.lowpass) {
            return;
        }
        if (typeof target === 'number' && this._activeMontage) {
            // Channel index can only refer to montage channels
            this._activeMontage.setLowpassFilter(target, value);
        }
        else if (typeof target === 'string') {
            if (scope === 'recording') {
                if (target === 'eeg') {
                    this._filters.lowpass = value;
                }
            }
            else if (this._activeMontage) {
                this._activeMontage.setLowpassFilter(target, value);
            }
        }
        this._activeMontage?.updateFilters();
        this.onPropertyUpdate('lowpass-filter');
    }
    setNotchFilter(value, target = 'eeg', scope = 'recording') {
        if (value === null) {
            value = 0;
        }
        else if (value < 0 || value === this._filters.notch) {
            return;
        }
        if (typeof target === 'number' && this._activeMontage) {
            // Channel index can only refer to montage channels
            this._activeMontage.setNotchFilter(target, value);
        }
        else if (typeof target === 'string') {
            if (scope === 'recording') {
                if (target === 'eeg') {
                    this._filters.notch = value;
                }
            }
            else if (this._activeMontage) {
                this._activeMontage.setNotchFilter(target, value);
            }
        }
        this._activeMontage?.updateFilters();
        this.onPropertyUpdate('notch-filter');
    }
    startCachingSignals() {
        // Start caching file data if recording was activated
        if (this.isActive && !this._signalCacheStatus[1]) {
            Log.debug("Starting to cache signals from file.", SCOPE);
            this._loader?.cacheSignalsFromUrl();
        }
    }
}
