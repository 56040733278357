/**
 * EpiCurrents Viewer generic file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { studyObjectTemplate } from 'LIB/loaders/GenericStudyLoader';
export default class GenericFileLoader {
    /**
     * File headers to mime type associations.
     * @remarks I may finish this some day, or may not. We'll see...
     */
    static MIME_TYPES;
    _contexts;
    _fileExtensions;
    _matchPatterns = [];
    _name;
    _study = studyObjectTemplate();
    constructor(name, contexts, namePatterns = [], fileExtensions = []) {
        for (const pattern of namePatterns) {
            this._matchPatterns.push(new RegExp(pattern, 'i'));
        }
        this._contexts = contexts;
        this._fileExtensions = fileExtensions;
        this._name = name;
    }
    get name() {
        return this._name;
    }
    get study() {
        return this._study;
    }
    async getMimeFromFile(file) {
        const header = new Uint8Array(await file.arrayBuffer()).subarray(0, 16);
        let headerText = '';
        for (const byte of header) {
            headerText += byte.toString(16);
        }
    }
    isSupportedContext(context) {
        for (const supportedCtx of this._contexts) {
            if (supportedCtx === context) {
                return true;
            }
        }
        return false;
    }
    async loadFile(source) {
        if (source.hasOwnProperty('url')) {
            return source;
        }
        return {
            file: source,
            format: null,
            mime: null,
            type: 'file',
            url: URL.createObjectURL(source),
        };
    }
    async loadFileUrl(source) {
        if (source.hasOwnProperty('url')) {
            return source;
        }
        return {
            file: null,
            format: null,
            mime: null,
            type: 'file',
            url: source,
        };
    }
    matchName(fileName) {
        for (const pattern of this._matchPatterns) {
            if (fileName.match(pattern)) {
                return true;
            }
        }
        for (const ext of this._fileExtensions) {
            if (fileName.indexOf(ext) !== -1) {
                return true;
            }
        }
        return false;
    }
    registerStudy(study) {
        if (study) {
            this._study = study;
        }
    }
}
