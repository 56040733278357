/**
 * EpiCurrents Viewer Vuex store EEG module.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const emg = {
    moduleName: {
        code: 'emg',
        full: 'Electromyography',
        short: 'EMG',
    },
    resources: [],
};
const getters = {
    activeEmgResource: (state) => () => {
        for (const resource of emg.resources) {
            if (resource.isActive) {
                return resource;
            }
        }
        return null;
    }
};
export { emg, getters };
