/**
 * EpiCurrents Viewer Vuex store app module.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const app = {
    activeDataset: null,
    datasets: [],
    id: '',
    moduleName: {
        code: 'app',
        full: 'Application',
        short: 'App',
    },
    plots: {
        biosignal: null,
    },
    runningId: 0,
    scope: '',
    userSettings: {
        'screenPPI': 'ScreenPpiCalibrator'
    }
};
const getters = {
    getActiveResource: (state) => () => {
        const activeSet = state.APP.activeDataset;
        if (!activeSet) {
            return null;
        }
        for (const resource of activeSet.resources) {
            if (resource.isActive) {
                return resource;
            }
        }
        return null;
    }
};
export { app, getters };
