/**
 * EpiCurrents Viewer settings.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { MB_BYTES } from "LIB/util/constants";
import { EEG } from "./eeg/EegSettings";
import { EMG } from "./emg/EmgSettings";
import { NCS } from "./ncs/NcsSettings";
import { MEG } from "./meg/MegSettings";
import Log from "scoped-ts-log";
const SCOPE = 'Settings';
const _propertyUpdateHandlers = [];
/**
 * Remove the properties that cannot be cloned to a worker and
 * return the rest as an object.
 * @returns SETTINGS with only clonable, non-proxied properties.
 */
const clonableSettings = () => {
    // Remove the private _userDefinable (it also can't be cloned).
    const outSettings = {};
    for (const mod in SETTINGS) {
        if (mod === '_CLONABLE') {
            // Avoid infinite call stack
            continue;
        }
        const clonable = {};
        for (const [field, value] of Object.entries(_settings[mod])) {
            if (!field.startsWith('_')) {
                clonable[field] = value;
            }
        }
        outSettings[mod] = clonable;
    }
    return outSettings;
};
const getFieldValue = (field, depth) => {
    // Traverse field's "path" to target property
    const fPath = field.split('.');
    // The value must be updated both in local app state and global settings
    let configFields = [SETTINGS];
    let i = 0;
    for (const f of fPath) {
        if (depth !== undefined && (depth >= 0 && i === depth ||
            depth < 0 && i === fPath.length - 1 + depth) ||
            depth === undefined && i === fPath.length - 1) {
            if (configFields[i][f] === undefined) {
                Log.warn(`Could not locate field '${field}': property '${fPath.slice(i).join('.')}' does not exist. Valid properties are '${Object.keys(configFields[i]).join("', '")}'.`, SCOPE);
                return undefined;
            }
            // Final field
            const config = configFields.pop();
            return config[f];
        }
        else {
            configFields.push(configFields[i][f]);
        }
        i++;
    }
    return undefined;
};
/**
 * Handler for a proxied settings object. Will proxy all object
 * properties unless the property name starts with an underscore.
 * @remarks
 * This implementation is not finished which is why the settings
 * object is bot proxied and still has the option to add property
 * update watchers.
 */
const proxyHandler = {
    get(target, key, receiver) {
        // Don't proxy static properties starting with an underscore
        if (typeof target[key] === 'object' && target[key] !== null && (typeof key === 'symbol' || !key.startsWith('_'))) {
            return new Proxy(target[key], proxyHandler);
        }
        else {
            return Reflect.get(target, key, receiver);
        }
    },
    set(target, key, value, receiver) {
        const success = Reflect.set(target, key, value, receiver);
        if (typeof key === 'symbol') {
            return success;
        }
        if (success) {
            if (Object.hasOwn(target, '_watchers')) {
                const handlers = target._watchers.get(key);
                if (handlers) {
                    for (const handler of handlers) {
                        Log.debug(`Calling a field update handler for ${key}.`, SCOPE);
                        handler(value);
                    }
                }
            }
        }
        return success;
    },
};
const _settings = {
    // Prevent prototype injection
    __proto__: null,
    /**
     * The settings object excluding any properties that
     * cannot be cloned (e.g. to a worker).
     */
    get _CLONABLE() {
        return clonableSettings();
    },
    app: {
        _userDefinable: {
            screenPPI: Number,
            theme: String,
        },
        _watchers: new Map(),
        dataChunkSize: 5 * MB_BYTES,
        fontawesomeLib: 'free',
        iconLib: 'fa',
        isMainComponent: true,
        logThreshold: 'DEBUG',
        maxDirectLoadSize: 10 * MB_BYTES,
        maxLoadCacheSize: 2000 * MB_BYTES,
        screenPPI: 96,
        theme: 'default',
    },
    eeg: EEG,
    emg: EMG,
    meg: MEG,
    ncs: NCS,
    modules: {
        MNE: true,
        ONNX: false,
    },
    addPropertyUpdateHandler(field, handler, caller) {
        for (const update of _propertyUpdateHandlers) {
            if ((!field || field === update.field) && handler === update.handler) {
                // Don't add the same handler twice
                return;
            }
        }
        // Get parent field
        const parentField = getFieldValue(field, -1);
        const propName = field.split('.').pop();
        // The value must be updated both in local app state and global settings
        if (parentField.hasOwnProperty(propName) && parentField.hasOwnProperty('_watchers')) {
            const existing = parentField._watchers.get(propName);
            if (existing) {
                existing.push(handler);
            }
            else {
                parentField._watchers.set(propName, [handler]);
            }
            if (caller) {
                _propertyUpdateHandlers.push({
                    caller: caller,
                    field: field,
                    handler: handler,
                });
            }
        }
        Log.debug(`Added a handler for ${field}.`, SCOPE);
    },
    removeAllPropertyUpdateHandlers() {
        const removed = _propertyUpdateHandlers.splice(0);
        for (const { caller, field, handler } of removed) {
            _settings.removePropertyUpdateHandler(field, handler);
        }
        Log.debug(`Removing all ${removed.length} property update handlers.`, SCOPE);
    },
    removeAllPropertyUpdateHandlersFor(caller) {
        for (let i = 0; i < _propertyUpdateHandlers.length; i++) {
            const update = _propertyUpdateHandlers[i];
            if (caller === update.caller) {
                _settings.removePropertyUpdateHandler(update.field, update.handler);
                i--;
            }
        }
    },
    removePropertyUpdateHandler(field, handler) {
        // Get parent field
        const parentField = getFieldValue(field, -1);
        const propName = field.split('.').pop();
        // The value must be updated both in local app state and global settings
        if (Object.hasOwn(parentField, propName) && Object.hasOwn(parentField, '_watchers')) {
            const existing = parentField._watchers.get(propName);
            for (let i = 0; i < existing.length; i++) {
                if (existing[i] === handler) {
                    // Remove the actual watcher
                    existing.splice(i, 1);
                    // Remove it from the list of handler references
                    for (let i = 0; i < _propertyUpdateHandlers.length; i++) {
                        const update = _propertyUpdateHandlers[i];
                        if ((!field || field === update.field) && handler === update.handler) {
                            const caller = _propertyUpdateHandlers.splice(i, 1)[0].caller || '';
                            Log.debug(`Removed ${field} handler${caller ? ' for ' + caller : ''}.`, SCOPE);
                            return;
                        }
                    }
                    break;
                }
            }
        }
        Log.debug(`Could not locate the requested ${field} handler.`, SCOPE);
    },
};
const SETTINGS = new Proxy(_settings, proxyHandler);
export default SETTINGS;
