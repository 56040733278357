/**
 * EpiCurrents Viewer NCS settings.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
export const NCS = {
    annotations: {
        color: [0, 0, 1, 0.75],
        convertPatterns: [],
        idColors: {},
        ignorePatterns: [],
        typeColors: {},
        width: 1,
    },
    antialiasing: true,
    border: {
        bottom: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
        left: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
        right: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
        top: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 0,
        },
    },
    channelSpacing: 1,
    cursor: {
        active: {
            color: [0, 0, 0.5, 0.75],
            style: 'solid',
            width: 2,
        },
        focused: {
            color: [0, 0, 0.5, 0.75],
            style: 'solid',
            width: 2,
        },
        inactive: {
            color: [0, 0, 0.5, 0.75],
            style: 'solid',
            width: 2,
        },
    },
    defaultMontages: {},
    defaultTimebase: {
        f: {
            div: 10,
            page: 100,
        },
        h: {
            div: 10,
            page: 100,
        },
        m: {
            div: 5,
            page: 50,
        },
        s: {
            div: 1,
            page: 10,
        },
        u: {
            div: 5,
            page: 50,
        },
    },
    defaultSensitivity: {
        f: {
            div: 10,
            page: 100,
        },
        h: {
            div: 10,
            page: 100,
        },
        m: {
            div: 1_000,
            page: 10_000,
        },
        s: {
            div: 5,
            page: 50,
        },
        u: {
            div: 100,
            page: 1000,
        },
    },
    defaultSetups: [],
    displayPolarity: -1,
    downsampleLimit: 0,
    filterPaddingSeconds: 0.05,
    filters: {
        highpass: {
            availableValues: [],
            default: 0,
        },
        lowpass: {
            availableValues: [],
            default: 0,
        },
        notch: {
            availableValues: [],
            default: 0,
        },
    },
    groupSpacing: 1,
    majorGrid: {
        show: true,
        color: [0, 0, 0, 0.25],
        style: 'solid',
        width: 2,
    },
    marker: {
        active: {
            color: [0, 0.25, 0.75, 1],
            style: 'line',
            width: 2,
        },
        focused: {
            color: [1, 0, 0, 1],
            style: 'line',
            width: 3,
        },
        inactive: {
            color: [0, 0, 0, 0.4],
            style: 'line',
            width: 2,
        },
    },
    markerColor: [0, 0, 0, 0.5],
    markerSize: 2,
    masterAxis: 'y',
    minorGrid: {
        show: true,
        color: [0, 0, 0, 0.15],
        style: 'solid',
        width: 1,
    },
    montages: {
        cacheMax: 0,
        preCache: false,
    },
    pageLength: 0.1,
    results: {
        display: true,
        width: 400,
    },
    scaleUnit: 'div',
    sensitivity: {
        div: {
            availableValues: [5, 7, 10, 20, 30, 50, 70, 100, 200, 300, 500, 1_000, 2_000, 3_000, 5_000],
            default: 100,
        },
        page: {
            availableValues: [50, 70, 100, 200, 300, 500, 700, 1_000, 2_000, 3_000, 5_000, 10_000, 20_000, 30_000, 50_000],
            default: 1000,
        },
    },
    sensitivityUnit: 'div',
    showHiddenChannels: false,
    showMissingChannels: false,
    timebase: {
        div: {
            availableValues: [0.5, 1, 2, 3, 5, 7, 10, 15, 20, 25, 50, 100],
            default: 5,
        },
        page: {
            availableValues: [5, 10, 20, 30, 50, 70, 100, 150, 200, 250, 500, 1_000],
            default: 50,
        },
    },
    timebaseUnit: 'div',
    timeline: {
        labelSpacing: 0,
    },
    trace: {
        color: [0.2, 0.2, 0.2, 1],
        theme: 'default',
        width: 1,
    },
    xDivCount: 10,
    yDivCount: 10,
    yPadding: 1,
};
