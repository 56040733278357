/**
 * EpiCurrents Viewer generic media resource.
 * This class serves only as as superclass for more spesific media classes.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import GenericAsset from "LIB/common/GenericAsset";
const SCOPE = 'GenericMediaResource';
export default class GenericMediaResource extends GenericAsset {
    /** Is this record selected as active in the UI */
    _active = false;
    _loaded = false;
    _url = '';
    constructor(name, context, type, url) {
        super(name, context, type);
        if (url) {
            this._url = url;
        }
    }
    get isActive() {
        return this._active;
    }
    set isActive(value) {
        this._active = value;
        this.onPropertyUpdate('is-active');
    }
    get isLoaded() {
        return this._loaded;
    }
    set isLoaded(value) {
        this._loaded = value;
        this.onPropertyUpdate('is-loaded');
    }
    get url() {
        return this._url;
    }
    /*
     * This method should be overridden in the child component.
     */
    getMainProperties() {
        return new Map();
    }
}
