/**
 * Original code
 * Author      Jonathan Lurie - http://me.jonahanlurie.fr
 * License     MIT
 * Link        https://github.com/jonathanlurie/edfdecoder
 * Lab         MCIN - http://mcin.ca/ - Montreal Neurological Institute
 *
 * Modifications
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
import * as codecutils from 'codecutils';
import { concatFloat32Arrays } from 'LIB/util/signal';
import { NUMERIC_ERROR_VALUE } from 'LIB/util/constants';
import EdfRecording from './EdfRecording';
const SCOPE = 'EdfDecoder';
/**
* An instance of EdfDecoder is used to decode an EDF file (or rather a buffer extracted from a
* EDF file).\
* To specify the input, use the method `setInput(buffer: ArrayBuffer)`.\
* Decoding is started with the method `decode()`.\
* Decoded result can be accessed via the property `output`.
* If the output is `null`, then the parser was not able to decode the file.
*/
export default class EdfDecoder {
    _fileType = 'edf';
    _inputBuffer = null;
    _output = null;
    /**
     * Create a EdfDecoder. If a buffer is provided, it will immediately
     * be set as the input buffer of this decoder.
     * @param buffer - ArrayBuffer to use as input (optional).
     * @param fileType - File type of the input (optional, assumed EDF).
     */
    constructor(buffer, fileType) {
        if (buffer) {
            this.setInput(buffer, fileType);
        }
    }
    /**
    * The output as an object. The output contains the the header (Object),
    * the raw (digital) signal as a Int16Array and the physical (scaled) signal
    * as a Float32Array.
    * @return the output.
    */
    get output() {
        return this._output;
    }
    appendInput(buffer) {
        if (!this._inputBuffer) {
            this._inputBuffer = buffer;
            return;
        }
        const inputView = new Uint8Array(this._inputBuffer);
        const newView = new Uint8Array(buffer);
        const totalView = new Uint8Array(inputView.length + newView.length);
        totalView.set(inputView);
        totalView.set(newView, inputView.length);
        this._inputBuffer = totalView.buffer;
    }
    /**
    * Set the buffer (most likey from a file) that contains some EDF data.
    * @param buffer - Buffer from the EDF file.
    * @param fileType - Buffer file type (assumed EDF, placeholder for possible BDF support in the future).
    */
    setInput(buffer, fileType) {
        this._output = null;
        this._inputBuffer = buffer;
        if (fileType) {
            this._fileType = fileType;
        }
    }
    /**
    * Decode the EDF file. This is done in two steps, first the header and then the data.
    * @return true on success, false on failure
    */
    decode() {
        const header = this.decodeHeader();
        if (!header) {
            Log.error(`Decoding EDF file was aborted because of header decoding error`, SCOPE);
            return false;
        }
        const data = this.decodeData(header.size, 0, header.header)?.signals;
        if (!data) {
            Log.error(`Decoding EDF file was aborted because of data decoding error`, SCOPE);
            return false;
        }
        return true;
    }
    /**
    * Decode EDF file data. Can only be called after the header is decoded or a header object provided.
    * @param dataOffset - Byte size of the header or byte index of the record to start from.
    * @param startRecord - Record number at dataOffset.
    * @param headers - EdfHeaders (optional, will use the decoded header if undefined).
    * @param buffer - Buffer to use instead of stored buffer data (optional).
    * @param range - Range of records to decode from buffer (optional, but required if a buffer is provided).
    * @param priorOffset - Time offset of the prior data (i.e. total gap time before buffer start, optinal, default 0).
    */
    decodeData(dataOffset, startRecord, headers, buffer, range, priorOffset = 0) {
        const dataBuffer = buffer ? buffer : this._inputBuffer;
        const useHeaders = headers || this._output?.headers;
        if (!dataBuffer) {
            Log.error("Cannot decode EDF data: an input buffer must be specified!", SCOPE);
            return null;
        }
        if (!useHeaders) {
            Log.error("Cannot decode EDF data: header has not been decoded yet!", SCOPE);
            return null;
        }
        const nRecs = useHeaders.dataRecordCount;
        if (range && range > nRecs) {
            Log.error("Cannot decode EDF data: given range is out of record bounds!", SCOPE);
            return null;
        }
        if (buffer !== undefined && range === undefined) {
            Log.error("Cannot decode EDF data: range must be specified if buffer is specified!", SCOPE);
            return null;
        }
        // In case of possible BDF support in the future
        const SampleType = this._fileType === 'edf' ? Int16Array : Int16Array;
        // the raw signal is the digital signal
        var rawSignals = new Array(useHeaders.signalCount);
        var physicalSignals = new Array(useHeaders.signalCount);
        const nDataRecords = Math.round(range ? range : useHeaders.dataRecordCount);
        const annotations = new Array(nDataRecords);
        const annotationSignals = [];
        const annotationProto = {
            annotator: null,
            channels: [],
            duration: 0,
            id: null,
            label: '',
            priority: 0,
            start: 0,
            text: '',
            type: "event"
        };
        // UTF-8 decoder for the annotation text parts
        const annotationDecoder = new TextDecoder('utf8');
        const getAnnotationFields = (startFrom, recordLen, existing) => {
            const annotations = existing || {
                recordStart: NUMERIC_ERROR_VALUE,
                fields: []
            };
            const fieldProps = {
                startTime: NUMERIC_ERROR_VALUE,
                duration: NUMERIC_ERROR_VALUE,
                entries: [],
            };
            // Create a view to the underlying buffer
            const byteArray = codecutils.CodecUtils.extractTypedArray(dataBuffer, startFrom, Uint8Array, recordLen);
            let fieldStart = startFrom;
            let durationNext = false;
            for (let i = startFrom; i < startFrom + recordLen; i++) {
                const baIdx = i - startFrom;
                if (byteArray[baIdx] === 20) {
                    // Field end byte
                    if (fieldProps.startTime === NUMERIC_ERROR_VALUE) {
                        fieldProps.startTime = parseFloat(codecutils.CodecUtils.getString8FromBuffer(buffer, i - fieldStart, fieldStart));
                        if (annotations.recordStart === NUMERIC_ERROR_VALUE && byteArray[baIdx + 1] === 20) {
                            annotations.recordStart = fieldProps.startTime;
                            // Skip the additional x20 byte
                            i++;
                        }
                    }
                    else if (durationNext) {
                        fieldProps.duration = parseFloat(codecutils.CodecUtils.getString8FromBuffer(buffer, i - fieldStart, fieldStart));
                        durationNext = false;
                    }
                    else {
                        // Decode annotation text part in UTF-8
                        fieldProps.entries.push(annotationDecoder.decode(dataBuffer.slice(fieldStart, i)));
                    }
                    fieldStart = i + 1;
                }
                else if (byteArray[baIdx] === 21) {
                    // Duration delimiter byte.
                    // This delimiter mus follow a start time field.
                    fieldProps.startTime = parseFloat(codecutils.CodecUtils.getString8FromBuffer(buffer, i - fieldStart, fieldStart));
                    durationNext = true;
                    fieldStart = i + 1;
                }
                else if (byteArray[baIdx] === 0) {
                    // End of annotation
                    if (fieldProps.entries.length) {
                        annotations.fields.push({
                            startTime: fieldProps.startTime,
                            duration: fieldProps.duration,
                            entries: [...fieldProps.entries],
                        });
                    }
                    if (byteArray[baIdx + 1] === 0) {
                        // No more annotations in this record
                        break;
                    }
                    fieldProps.startTime = NUMERIC_ERROR_VALUE;
                    fieldProps.duration = 0;
                    fieldProps.entries = [];
                    fieldStart = i + 1;
                }
            }
            return annotations;
        };
        // Allocate elements for signals, marking possible EDF Annotations channels
        for (let i = 0; i < useHeaders.signalCount; i++) {
            if (useHeaders.edfPlus && useHeaders.signalInfo[i].label.toLowerCase() === 'edf annotations') {
                annotationSignals.push(i);
            }
            rawSignals[i] = new Array(nDataRecords);
            physicalSignals[i] = new Array(nDataRecords);
        }
        const dataGaps = new Map();
        let startCorrection = 0;
        // The raw data is a list of records containing a chunk of each signal
        for (let r = 0; r < nDataRecords; r++) {
            const expectedRecordStart = (startRecord + r) * useHeaders.dataRecordDuration + priorOffset;
            // Read the record for each signal
            let recAnnotations = null;
            for (let i = 0; i < useHeaders.signalCount; i++) {
                const sigInfo = useHeaders.signalInfo[i];
                const nSamples = sigInfo.sampleCount;
                const nBytes = nSamples * (SampleType.BYTES_PER_ELEMENT);
                let isAnnotation = false;
                // Process annotation signal differently
                if (useHeaders.edfPlus && sigInfo.label === 'EDF Annotations') {
                    const parsed = getAnnotationFields(dataOffset, nBytes, recAnnotations || undefined);
                    // Save possible discontinuity in signal data
                    if (useHeaders.discontinuous && parsed.recordStart > expectedRecordStart) {
                        dataGaps.set((startRecord + r) * useHeaders.dataRecordDuration, parsed.recordStart - expectedRecordStart);
                        priorOffset += parsed.recordStart - expectedRecordStart;
                    }
                    else if (parsed.recordStart < expectedRecordStart + startCorrection) {
                        Log.warn(`EDF file has duplicate record start annotations, file data may be corrupted (expected start time ${expectedRecordStart} in data record ${r}, got ${parsed.recordStart}).`, SCOPE);
                        // Don't repeat the same warning on all consecutive records
                        startCorrection = parsed.recordStart - expectedRecordStart;
                    }
                    if (recAnnotations) {
                        recAnnotations.fields.push(...parsed.fields);
                    }
                    else {
                        recAnnotations = parsed;
                    }
                    isAnnotation = true;
                }
                const rawSignal = codecutils.CodecUtils.extractTypedArray(dataBuffer, dataOffset, SampleType, nSamples);
                rawSignals[i][r] = rawSignal;
                // Convert digital signal to physical signal
                const physicalSignal = new Float32Array(rawSignal.length).fill(0);
                if (!isAnnotation) {
                    for (let index = 0; index < nSamples; index++) {
                        // https://edfrw.readthedocs.io/en/latest/specifications.html#converting-digital-samples-to-physical-dimensions
                        physicalSignal[index] = sigInfo.unitsPerBit * (rawSignal[index] + sigInfo.digitalOffset);
                        //(
                        //    ((rawSignal[index] - sigInfo.digitalMinimum) / digitalSignalRange )*physicalSignalRange
                        //) + sigInfo.physicalMinimum
                    }
                }
                physicalSignals[i][r] = physicalSignal;
                dataOffset += nBytes;
            }
            // Add parsed annotations
            if (recAnnotations) {
                for (const anno of recAnnotations.fields) {
                    for (const entry of anno.entries) {
                        annotations.push(Object.assign({}, annotationProto, {
                            start: anno.startTime,
                            duration: anno.duration,
                            label: entry
                        }));
                    }
                }
            }
        }
        if (!buffer) {
            // Refresh output with actual signal data
            this._output = new EdfRecording(useHeaders, rawSignals, physicalSignals, annotations, dataGaps);
        }
        else {
            // Add possible parsed annotations and data gaps
            if (annotations.length) {
                this._output?.addAnnotations(...annotations);
            }
            if (dataGaps.size) {
                this._output?.addDataGaps(dataGaps);
            }
        }
        if (!range || range > 1) {
            return {
                annotations: annotations,
                dataGaps: dataGaps,
                signals: physicalSignals.map((sigSet) => { return concatFloat32Arrays(...sigSet); }),
            };
        }
        else {
            return {
                annotations: annotations,
                dataGaps: dataGaps,
                signals: physicalSignals.map(sigSet => sigSet[0]),
            };
        }
    }
    /**
    * Decode EDF file header.
    * @param noSignals - Only parse the general part of the header and stop at signal data.
    * @return object { header: EdfHeader, size: header size in bytes (= data offset) }
    */
    decodeHeader(noSignals = false) {
        if (!this._inputBuffer) {
            Log.error("Cannot decode EDF header: an input buffer must be specified!", SCOPE);
            return;
        }
        // In case of possible BDF support in the future
        const SampleType = this._fileType === 'edf' ? Int16Array : Int16Array;
        const header = {
            dataFormat: '',
            dataRecordCount: 0,
            dataRecordDuration: 0,
            discontinuous: false,
            edfPlus: false,
            headerRecordBytes: 0,
            localRecordingId: '',
            patientId: '',
            recordByteSize: 0,
            recordingDate: null,
            reserved: '',
            signalCount: 0,
            signalInfo: [],
        };
        let offset = 0;
        // Attempt to parse each consecutive field from the header.
        // Vital field parsing errors abort the process in addition to logging an error.
        // EDF field values are padded to standard length with empty spaces, so trim the results.
        Log.debug(`EDF header decoding started.`, SCOPE);
        try {
            // 8 ascii : version of this data format (0)
            header.dataFormat = codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 8, offset).trim();
            Log.debug(`Data format is ${header.dataFormat}.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse data format EDF header field!`, SCOPE, e);
            return;
        }
        offset += 8;
        try {
            // 80 ascii : local patient identification
            header.patientId = codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 80, offset).trim();
            Log.debug(`Patient ID is ${header.patientId}.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse patient ID EDF header field!`, SCOPE, e);
        }
        offset += 80;
        try {
            // 80 ascii : local recording identification
            header.localRecordingId = codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 80, offset).trim();
            Log.debug(`Local recording ID is ${header.localRecordingId}.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse local recording ID EDF header field!`, SCOPE, e);
        }
        offset += 80;
        try {
            // 8 ascii : startdate of recording (dd.mm.yy)
            const recordingStartDate = codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 8, offset).trim();
            offset += 8;
            // 8 ascii : starttime of recording (hh.mm.ss)
            const recordingStartTime = codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 8, offset).trim();
            offset += 8;
            const date = recordingStartDate.split(".");
            // 1985 breakpoint
            if (parseInt(date[2]) >= 85) {
                date[2] = `19${date[2]}`;
            }
            else {
                date[2] = `20${date[2]}`;
            }
            const time = recordingStartTime.split(".");
            header.recordingDate = new Date(date[2], parseInt(date[1]) - 1, date[0], time[0], time[1], time[2], 0);
            Log.debug(`Starting datetime is ${header.recordingDate.toDateString()}.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse starting date/time EDF header field!`, SCOPE, e);
            offset += 16;
        }
        try {
            // 8 ascii : number of bytes in header record
            header.headerRecordBytes = parseInt(codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 8, offset).trim());
            Log.debug(`Header record size is ${header.headerRecordBytes} bytes.`, SCOPE);
        }
        catch (e) {
            // Number of bytes can be calculated manually as well
            Log.error(`Failed to parse number of bytes EDF header field!`, SCOPE, e);
        }
        offset += 8;
        try {
            // 44 ascii : reserved
            header.reserved = codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 44, offset);
            if (header.reserved.toUpperCase().startsWith('EDF+')) {
                header.edfPlus = true;
                if (header.reserved.toUpperCase().substring(4, 5) === 'D') {
                    header.discontinuous = true;
                    Log.debug(`File is using EDF+ specification, discontinuous record.`, SCOPE);
                }
                else {
                    Log.debug(`File is using EDF+ specification, continuous record.`, SCOPE);
                }
            }
        }
        catch (e) {
            Log.error(`Failed to parse reserved EDF header field!`, SCOPE, e);
        }
        offset += 44;
        try {
            // 8 ascii : number of data records
            // Note: Number of records can be -1 during recording, but currently only offline analysis is supported
            header.dataRecordCount = parseInt(codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 8, offset).trim());
            Log.debug(`${header.dataRecordCount} data records in file.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse number of data records EDF header field!`, SCOPE, e);
            return;
        }
        offset += 8;
        try {
            // 8 ascii : duration of a data record, in seconds
            header.dataRecordDuration = parseFloat(codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 8, offset).trim());
            Log.debug(`Data recordduration is ${header.dataRecordDuration} seconds.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse duration of data record EDF header field!`, SCOPE, e);
            return;
        }
        offset += 8;
        try {
            // 4 ascii : number of signals (ns) in data record
            header.signalCount = parseInt(codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, 4, offset).trim());
            Log.debug(`${header.signalCount} signals in file.`, SCOPE);
        }
        catch (e) {
            Log.error(`Failed to parse number of signals EDF header field!`, SCOPE, e);
            return;
        }
        offset += 4;
        // Stop here if signals are not needed
        if (noSignals) {
            // Generate an "empty" output object from the header information
            this._output = new EdfRecording(header, [], []);
            return {
                size: 0,
                header: header
            };
        }
        /** Parse signal info fields */
        const getAllSections = (sizeOfEachThing) => {
            const allThings = [];
            for (let i = 0; i < header.signalCount; i++) {
                try {
                    allThings.push(codecutils.CodecUtils.getString8FromBuffer(this._inputBuffer, sizeOfEachThing, offset).trim());
                }
                catch (e) {
                    Log.error(`Failed to parse signal info at index ${i} from EDF header!`, SCOPE, e);
                    return null;
                }
                offset += sizeOfEachThing;
            }
            return allThings;
        };
        const signalInfoArrays = {
            // ns * 16 ascii : ns * label (e.g. EEG Fpz-Cz or Body temp)
            label: getAllSections(16) || '--',
            // ns * 80 ascii : ns * transducer type (e.g. AgAgCl electrode)
            transducerType: getAllSections(80) || '--',
            // ns * 8 ascii : ns * physical dimension (e.g. uV or degreeC)
            physicalUnit: getAllSections(8) || '--',
            // ns * 8 ascii : ns * physical minimum (e.g. -500 or 34)
            physicalMinimum: getAllSections(8) || '0',
            // ns * 8 ascii : ns * physical maximum (e.g. 500 or 40)
            physicalMaximum: getAllSections(8) || '0',
            // ns * 8 ascii : ns * digital minimum (e.g. -2048)
            digitalMinimum: getAllSections(8) || '0',
            // ns * 8 ascii : ns * digital maximum (e.g. 2047)
            digitalMaximum: getAllSections(8) || '0',
            // ns * 80 ascii : ns * prefiltering (e.g. HP:0.1Hz LP:75Hz)
            prefiltering: getAllSections(80) || '--',
            // ns * 8 ascii : ns * nr of samples in each data record
            sampleCount: getAllSections(8) || '0',
            // ns * 32 ascii : ns * reserved
            reserved: getAllSections(32) || '',
        };
        const signalInfo = [];
        header.signalInfo = signalInfo;
        for (let i = 0; i < header.signalCount; i++) {
            const digMax = parseInt(signalInfoArrays.digitalMaximum[i]);
            const digMin = parseInt(signalInfoArrays.digitalMinimum[i]);
            const physMax = parseFloat(signalInfoArrays.physicalMaximum[i]);
            const physMin = parseFloat(signalInfoArrays.physicalMinimum[i]);
            const unitsPerBit = (physMax - physMin) / (digMax - digMin);
            const samplingRate = parseInt(signalInfoArrays.sampleCount[i]) / header.dataRecordDuration;
            signalInfo.push({
                digitalMaximum: digMax,
                digitalMinimum: digMin,
                digitalOffset: physMax / unitsPerBit - digMax,
                label: signalInfoArrays.label[i],
                physicalMaximum: physMax,
                physicalMinimum: physMin,
                physicalUnit: signalInfoArrays.physicalUnit[i],
                prefiltering: signalInfoArrays.prefiltering[i],
                reserved: signalInfoArrays.reserved[i],
                sampleCount: parseInt(signalInfoArrays.sampleCount[i]),
                samplingRate: signalInfoArrays.label[i] !== 'EDF Annotations' ? samplingRate : 0,
                transducerType: signalInfoArrays.transducerType[i],
                unitsPerBit: unitsPerBit,
            });
            header.recordByteSize += (header.signalInfo[i].sampleCount || 0) * SampleType.BYTES_PER_ELEMENT;
            Log.debug([
                `Signal [${i}]:`,
                `Label: ${signalInfoArrays.label[i]},`,
                `Sampling rate: ${samplingRate},`,
                `Physical unit: ${signalInfoArrays.physicalUnit[i]}.`,
            ], SCOPE);
        }
        // Generate an "empty" output object from the header information
        this._output = new EdfRecording(header, [], []);
        return {
            size: offset,
            header: header
        };
    }
}
