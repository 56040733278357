/**
 * EpiCurrents Viewer general study loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
import GenericBiosignalResource from 'LIB/common/GenericBiosignalResource';
const studyObjectTemplate = () => {
    return {
        data: null,
        files: [],
        format: '',
        meta: {},
        name: '',
        scope: '',
        type: '',
        version: '1.0'
    };
};
const URL = window.URL || window.webkitURL;
const CONFIG_FILE_NAME = 'epicviewer_study_config.json';
const SCOPE = 'StudyLoader';
class GenericStudyLoader {
    _fileLoaders = [];
    /**
     * Load directory files as part of a single study.
     * @param dir directory as FileSystemItem
     * @param config optional configuration
     * @returns StudyObject if successful, null otherwise
     */
    async loadFromDirectory(dir, config = {}) {
        // Load the first file and add directory contents as study files.
        // Pass the directory name as default study name.
        const curFile = dir.files[0];
        const study = studyObjectTemplate();
        Object.assign(study, { name: dir.name }, config);
        if (curFile.file) {
            const fileUrl = URL.createObjectURL(curFile.file);
            await this.loadFromFileUrl(fileUrl, Object.assign({ name: curFile.name }, config?.studies ? config.studies[dir.name] : {}, config?.studies ? config.studies[dir.path] : {}), study);
        }
        else if (curFile.url) {
            // Fetch the file from url
            await this.loadFromFileUrl(curFile.url, Object.assign({ name: curFile.name }, config?.studies ? config.studies[dir.name] : {}, config?.studies ? config.studies[dir.path] : {}), study);
        }
        if (study) {
            for (let i = 1; i < dir.files.length; i++) {
                if (dir.files[i].file) {
                    const fileUrl = URL.createObjectURL(dir.files[i].file);
                    if (study.scope !== 'radiology') {
                        // Load additional files, if this is not an image series
                        await this.loadFromFileUrl(fileUrl, Object.assign({ name: dir.files[i].name }, config?.studies ? config.studies[dir.files[i].name] : undefined, config?.studies ? config.studies[dir.files[i].path] : undefined), study);
                    }
                    else {
                        // For image series, just add the files
                        study.files.push({
                            file: null,
                            format: 'dicom',
                            mime: null,
                            name: dir.files[i].name,
                            type: 'image',
                            url: fileUrl,
                        });
                    }
                }
                else if (dir.files[i].url) {
                    study.files.push({
                        file: null,
                        format: '',
                        mime: null,
                        name: dir.files[i].name,
                        type: '',
                        url: dir.files[i].url,
                    });
                }
            }
            // In case this is a biosignal record, check for video attachments
            if (study.type.startsWith(`${GenericBiosignalResource.CONTEXTS.BIOSIGNAL}:`)) {
                for (let i = 0; i < study.files.length; i++) {
                    if (study.files[i].type === 'video' || study.files[i].name.endsWith('.mp4')) {
                        // Check for known naming schemes
                        const fName = study.files[i].name.toLowerCase();
                        let startDif = 0;
                        let group = 0;
                        // Stratus is N_YYYY-MM-DD_hh_mm_ssZ
                        const namePart = fName?.split('.')[0];
                        const stratus = namePart?.match(/(\d)_(\d\d\d\d)-(\d\d)-(\d\d)_(\d\d)_(\d\d)_(\d\d)z/);
                        if (stratus) {
                            if (!study.meta.videos) {
                                study.meta.videos = [];
                            }
                            group = parseInt(stratus[1]);
                            const videoStart = new Date(`${stratus[2]}-${stratus[3]}-${stratus[4]}T${stratus[5]}:${stratus[6]}:${stratus[7]}Z`);
                            // Calculate the difference between video start and biosignal record start, if possible
                            startDif = 0; //videoStart && study.meta.startDate
                            //? (videoStart.getTime() - study.meta.startDate.getTime())/1000
                            //: 0
                        }
                        // Figuring out video duration requires creating a video element and preloading the metadata
                        const loadVideoMeta = (study) => new Promise((resolve, reject) => {
                            try {
                                const video = document.createElement('video');
                                video.preload = 'metadata';
                                video.onloadedmetadata = () => {
                                    // Save metadata before removing the element
                                    const meta = [video.duration];
                                    resolve(meta);
                                };
                                video.onerror = () => {
                                    reject();
                                };
                                video.src = study.files[i].url;
                            }
                            catch (e) {
                                reject();
                            }
                        });
                        const [duration] = await loadVideoMeta(study) || [0];
                        // Add the video as attachment and remove it from prime files
                        study.meta.videos.push({
                            group: group,
                            endTime: startDif + duration,
                            startTime: startDif,
                            syncPoints: [],
                            url: study.files[i].url
                        });
                        study.files.splice(i, 1);
                        // Prevent skipping over the next file
                        i--;
                    }
                }
            }
            else if (study.files.length > 1 && study.type.startsWith('radiology') && !study.type.endsWith(':series')) {
                // Add series tag to other study types with multiple files
                study.type += ':series';
            }
        }
        return study;
    }
    /**
     * Load study properties from a single file.
     * @param fileUrl URL to the file
     * @param config optional study configuration
     * @param study optional StudyObject to append file to
     * @return a promise containing the loaded study as StudyObject
     */
    async loadFromFileUrl(fileUrl, config = {}, study) {
        if (!study) {
            study = studyObjectTemplate();
            if (config) {
                Object.assign(study, config);
            }
        }
        Log.debug(`Started loading a study from URL ${fileUrl} (${config.name}).`, SCOPE);
        // Try to load the file, according to extension
        const urlEnd = fileUrl.split('/').pop();
        const fName = config.name || urlEnd || '';
        for (const loader of this._fileLoaders) {
            if (config.context && !loader.isSupportedContext(config.context)) {
                continue;
            }
            if (loader.matchName(fName)) {
                Log.debug(`File ${fName} matched a loader, loading file contents...`, SCOPE);
                loader.registerStudy(study);
                if (await loader.loadFileUrl(fileUrl, { name: fName })) {
                    break;
                }
            }
        }
        if (fName.endsWith('.zip')) {
            // Zip files would be tricky. They would first have to be decompressed
            // and then passed through this again.
        }
        else if (fName.endsWith('.jpg') || fName.endsWith('.jpeg') ||
            fName.endsWith('.png') || fName.endsWith('.gif')) {
            // TODO: Implement image file loaders?
        }
        else if ((fName.endsWith('.mp4') || fName.endsWith('.m4v') || fName.endsWith('.webm'))
            && !study.meta.videos) {
            // HTML5-compatible video file
            // Fetch the file name end as file format
            const format = fName.split('.').pop();
            study.files.push({
                file: null,
                format: format,
                mime: null,
                name: fName,
                type: 'video',
                // Video files require a URL to play in the browser
                url: fileUrl,
            });
            // Video files can be attachments, so only update study format and type if they are empty
            if (!study.format) {
                study.format = format;
            }
            if (!study.type) {
                study.type = 'video';
            }
        }
        else {
            /*
            // DICOM files may not have any extension, try DICOM first
            try {
                const byteArray = new Uint8Array(await file.arrayBuffer())
                const dataSet = dicomParser.parseDicom(byteArray)
                if (!study.format) {
                    // DICOM format
                    study.format = 'dicom'
                }
                if (!study.meta.instanceId) {
                    // Save study instance UID
                    study.meta.instanceId = dataSet.string('x0020000d') || ''
                }
                if (!study.meta.modality) {
                    study.meta.modality = (dataSet.string('x00080060') || '').toUpperCase()
                }
                // First try if this is a DICOM image file
                const imageType = dataSet.string('x00080008')
                const imageModality = dataSet.string('x00080060')
                if (imageType || (imageModality && VALID_MODALITIES.RADIOLOGY.indexOf(imageModality) !== -1)) {
                    // This is a radiological image
                    if (!study.scope) {
                        study.scope = 'radiology'
                    }
                    // Use the file as data object for WADOImageLoader
                    study.data = file
                    if (!study.type) {
                        const typeParts = (imageType || '\\\\').split('\\')
                        // Part 3 defines a possible localizer (topogram) image
                        if (typeParts[2]?.toLowerCase() === 'localizer') {
                            study.type = 'image:topogram'
                        } else {
                            study.type = 'image'
                        }
                    }
                    study.meta.type = imageType
                    study.meta.modality = imageModality
                    // Add possible related study instances
                    if (dataSet.elements.x00081140 && dataSet.elements.x00081140.items) {
                        for (const relItem of dataSet.elements.x00081140.items) {
                            if (relItem.dataSet) {
                                if (!study.meta.relatedStudies) {
                                    study.meta.relatedStudies = []
                                }
                                study.meta.relatedStudies.push(relItem.dataSet.string('x00081150'))
                            }
                        }
                    }
                } else if (dataSet.elements.x54000100) {
                    // This is a waveform sequence
                    if (study.meta.modality === 'ECG') {
                        if (!study.scope) {
                            study.scope = 'ekg'
                        }
                        if (!study.type) {
                            study.type = dataSet.string('x00081030') || ''
                        }
                        // Use the parsed dataset as data object
                        study.data = dataSet
                    }
                } else if (dataSet.elements.x00420011) {
                    // This is an encapsulated document
                    if (!study.scope) {
                        study.scope = 'document'
                    }
                    if (!study.name) {
                        study.name = dataSet.string('x00420010') || ''
                    }
                    if (!study.meta.mime) {
                        study.meta.mime = dataSet.string('x00420012') || ''
                    }
                    // Document data can be retrieved from x00420011
                    // study.data = dataSet.string('x00420011')
                }
            } catch (e) {
                if (typeof e === 'string' && (e as string).indexOf('DICM prefix not found') >= 0) {
                    // This was not a DICOM file, try something else
                } else {
                    console.error(e)
                }
            }
        */
        }
        if (!study.name) {
            // Use file name as default
            study.name = config.name || 'Study';
        }
        return study;
    }
    /**
     * Recurse a given FileSystemItem and load each contained study.
     * @param fileTree FileSystemItem generated by one of the file loaders.
     * @param config optional configuration detailing the contained studies.
     * @return a promise containing the loaded studies as { title: string, date?: string, studies: StudyObject[] }
     */
    async loadFromFsItem(fileTree, config = {}) {
        if (!fileTree) {
            return [];
        }
        const collections = [];
        let rootDir = fileTree;
        while (!rootDir.files.length && rootDir.directories.length === 1) {
            // Recurse until we arrive at the root folder of the image sets
            rootDir = rootDir.directories[0];
        }
        // Check for possible config file in the root directory
        if (rootDir.files.length) {
            for (let i = 0; i < rootDir.files.length; i++) {
                if (rootDir.files[i].name === CONFIG_FILE_NAME) {
                    // Remove the config file from the directory
                    const confFile = rootDir.files.splice(i, 1)[0];
                    // Attempt to read config from the file
                    await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = (e) => {
                            const result = JSON.parse(e.target.result);
                            resolve(result);
                        };
                        reader.onerror = (e) => {
                            reject(e);
                        };
                        config = reader.readAsText(confFile.file);
                    }).then((json) => {
                        config = Object.assign(json, config);
                    }).catch(e => {
                        Log.error(`Could not load config from ${confFile.path}.`, SCOPE, e);
                    });
                    break;
                }
            }
        }
        // Make sure there is a collections and studies property on config
        if (!config.hasOwnProperty('collections')) {
            config.collections = {};
        }
        if (!config.hasOwnProperty('studies')) {
            config.studies = {};
        }
        const studyName = rootDir.name;
        // Next, check if this is a single file dir or several dirs
        if (!rootDir.directories.length && rootDir.files.length) {
            const studies = [];
            if (!rootDir.path) {
                // If this is the "pseudo" root directory, add each file as a separate study
                // (as they were dragged as separate files into the viewer)
                for (let i = 0; i < rootDir.files.length; i++) {
                    const curFile = rootDir.files[i];
                    let study;
                    if (curFile.file) {
                        const fileUrl = URL.createObjectURL(curFile.file);
                        study = await this.loadFromFileUrl(fileUrl, Object.assign({ name: curFile.name }, 
                        // Path > name in specificity
                        config.studies[curFile.name], config.studies[curFile.path]));
                    }
                    else if (curFile.url) {
                        // Fetch the file from url
                        study = await this.loadFromFileUrl(curFile.url, Object.assign({ name: curFile.name }, config.studies[curFile.name], config.studies[curFile.path]));
                    }
                    studies.push(study);
                }
            }
            else {
                // Add all files as parts of the same study
                const study = await this.loadFromDirectory(rootDir, config);
                if (study) {
                    // Only add successfully loaded studies
                    studies.push(study);
                }
            }
            collections.push(Object.assign({ studies: studies }, { title: rootDir.name }, config.collections[rootDir.name], config.collections[rootDir.path]));
        }
        else if (rootDir.directories.length) {
            // Check if this directory contains several collections.
            let visitDirs = [rootDir];
            for (let i = 0; i < rootDir.directories.length; i++) {
                // Allow single nested directories inside studies as well
                while (!rootDir.directories[i].files.length && rootDir.directories[i].directories.length === 1) {
                    rootDir.directories[i] = rootDir.directories[i].directories[0];
                }
                if (rootDir.directories[i].directories.length) {
                    visitDirs = rootDir.directories;
                }
            }
            // Try to add each individual dir as a separate study.
            // First check that each directory really contains only files, skip those that don't.
            for (let visitDir of visitDirs) {
                const studies = [];
                for (let i = 0; i < visitDir.directories.length; i++) {
                    const curDir = visitDir.directories[i];
                    if (curDir.directories.length) {
                        Log.warn(`${curDir.path} was omitted because it contained subdirectories.`, SCOPE);
                        continue;
                    }
                    else if (!curDir.files.length) {
                        Log.warn(`${curDir.path} was omitted because it was empty.`, SCOPE);
                        continue;
                    }
                    else {
                        const study = await this.loadFromDirectory(curDir, config);
                        if (study) {
                            // Only add successfully loaded studies
                            studies.push(study);
                        }
                    }
                }
                const collection = Object.assign({ studies: studies }, { title: visitDir.name }, config.collections[visitDir.name], config.collections[visitDir.path]);
                collections.push(collection);
            }
        }
        else {
            Log.warn("Dropped item had an empty root directory!", SCOPE);
        }
        // Order > date when sorting
        collections.sort((a, b) => { return (parseInt(a.date) || 0) - (parseInt(b.date) || 0); });
        collections.sort((a, b) => { return (parseInt(a.order) || 0) - (parseInt(b.order) || 0); });
        return collections;
    }
    registerFileLoader(loader) {
        for (const ldr of this._fileLoaders) {
            if (ldr === loader) {
                return;
            }
        }
        this._fileLoaders.push(loader);
    }
}
export { GenericStudyLoader, studyObjectTemplate };
