/**
 * EpiCurrents Viewer Vuex store actions.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { store } from 'STORE';
import { getters as docGetters } from './modules/document';
import { getters as eegGetters } from './modules/eeg';
import { getters as emgGetters } from './modules/emg';
import { getters as ncsGetters } from './modules/ncs';
import { getters as megGetters } from './modules/meg';
import SETTINGS from 'CONFIG/Settings';
import Log from 'scoped-ts-log';
import MixedMediaDataset from 'LIB/datasets/MixedMediaDataset';
const SCOPE = "StoreActions";
/**
 * Keep track of components that already have their styles added
 * to the shadow root to avoid adding them multiple times.
 */
const COMPONENT_STYLES_ADDED = [];
/**
 * Get the active BiosignalResource or null if there is none.
 */
const getActiveBiosignalResource = () => {
    return store.state.APP.activeDataset
        ? store.state.APP.activeDataset
            .getResourcesByType('eeg', 'emg', 'meg', 'ncs').filter((r) => r.isActive)[0] ||
            null
        : eegGetters.activeEegResource(store.state)() ||
            emgGetters.activeEmgResource(store.state)() ||
            ncsGetters.activeNcsResource(store.state)() ||
            megGetters.activeMegResource(store.state)() ||
            null;
};
/**
 * Get the active DocumentResource or null if there is none.
 */
const getActiveDocumentResource = () => {
    return store
        ? docGetters.activeDocResource(store.state)()
        : null;
};
const getActiveResource = () => {
    return store
        ? getActiveBiosignalResource() ||
            getActiveDocumentResource()
        : null;
};
/**
 * Disable all other resources, except for the resource at `index`
 * in the given `scope`.
 * @param scope - scope of the resource to skip
 * @param index - index of the resource to skip (null will disable all resources)
 */
const disableAllOtherResources = (scope, skip) => {
    // In these methods we check for isActive before assigning it to false,
    // because every assignment triggers possible property update watchers.
    if (store.state.APP.activeDataset) {
        for (const item of store.state.APP.activeDataset.resources) {
            if (item.isActive && (!skip || (item.id !== skip.id))) {
                item.isActive = false;
            }
        }
        return;
    }
    /*
    for (let i=0; i<store.state.DOC.resources.length; i++) {
        const doc = store.state.DOC.resources[i]
        if (doc.isActive &&(!skip || (scope !== 'document' || doc.id !== skip.id))) {
            doc.isActive = false
        }
    }
    for (let i=0; i<store.state.EEG.resources.length; i++) {
        const eeg = store.state.EEG.resources[i]
        if (eeg.isActive && (!skip || (scope !== 'eeg' || eeg.id !== skip.id))) {
            eeg.isActive = false
        }
    }
    for (let i=0; i<store.state.EMG.resources.length; i++) {
        const emg = store.state.EMG.resources[i]
        if (emg.isActive && (!skip || (scope !== 'emg' || emg.id !== skip.id))) {
            emg.isActive = false
        }
    }
    for (let i=0; i<store.state.NCS.resources.length; i++) {
        const ncs = store.state.NCS.resources[i]
        if (ncs.isActive && (!skip || (scope !== 'ncs' || ncs.id !== skip.id))) {
            ncs.isActive = false
        }
    }
    for (let i=0; i<store.state.MEG.resources.length; i++) {
        const meg = store.state.MEG.resources[i]
        if (meg.isActive && (!skip || (scope !== 'meg' || meg.id !== skip.id))) {
            meg.isActive = false
        }
    }
    */
};
/**
 * Disable resources from all other scopes, except for the given `scope`.
 * @param scope - scope to skip
 */
const disableAllOtherScopes = (scope) => {
    // In these methods we check for isActive before assigning it to false,
    // because every assignment triggers possible property update watchers.
    if (scope !== 'document') {
        for (const doc of store.state.DOC.resources) {
            if (doc.isActive) {
                doc.isActive = false;
            }
        }
    }
    if (scope !== 'eeg') {
        for (const eeg of store.state.EEG.resources) {
            if (eeg.isActive) {
                eeg.isActive = false;
            }
        }
    }
    if (scope !== 'emg') {
        for (const emg of store.state.EMG.resources) {
            if (emg.isActive) {
                emg.isActive = false;
            }
        }
    }
    if (scope !== 'ncs') {
        for (const ncs of store.state.NCS.resources) {
            if (ncs.isActive) {
                ncs.isActive = false;
            }
        }
    }
    if (scope !== 'meg') {
        for (const meg of store.state.MEG.resources) {
            if (meg.isActive) {
                meg.isActive = false;
            }
        }
    }
};
var ActionTypes;
(function (ActionTypes) {
    ActionTypes["ADD_COMPONENT_STYLES"] = "add-component-styles";
    ActionTypes["ADD_RESOURCE"] = "add-resource";
    ActionTypes["DISPLAY_VIEWER"] = "display-viewer";
    ActionTypes["LOAD_DATASET_FOLDER"] = "load-dataset-folder";
    ActionTypes["LOAD_STUDY_FOLDER"] = "load-study-folder";
    ActionTypes["LOAD_STUDY_URL"] = "load-study-url";
    ActionTypes["MOUSE_LEFT_APP"] = "mouse-left-app";
    ActionTypes["OVERLAY_CLICKED"] = "overlay-clicked";
    ActionTypes["SET_ACTIVE_RESOURCE"] = "set-active-resource";
    ActionTypes["SET_APP_SCOPE"] = "set-app-scope";
    ActionTypes["SET_SETTINGS_VALUE"] = "set-settings-value";
    ActionTypes["TOGGLE_FULLSCREEN"] = "toggle-fullscreen";
    // Datasets
    ActionTypes["ADD_DATASET"] = "add-dataset";
    ActionTypes["SET_ACTIVE_DATASET"] = "set-active-dataset";
    // Document actions
    ActionTypes["DOC_ADD_RESOURCE"] = "doc-add-resource";
    // EEG actions
    ActionTypes["EEG_ADD_RESOURCE"] = "eeg-add-resource";
    ActionTypes["SET_ACTIVE_EEG"] = "set-active-eeg";
    // EMG actions
    ActionTypes["EMG_ADD_RESOURCE"] = "emg-add-resource";
    ActionTypes["SET_ACTIVE_EMG"] = "set-active-emg";
    ActionTypes["SET_JITTER"] = "set-jitter";
    ActionTypes["SET_TRIGGERED"] = "set-triggered";
    // NCS actions
    ActionTypes["SET_ACTIVE_ENG"] = "set-active-ncs";
    ActionTypes["NCS_ADD_RESOURCE"] = "ncs-add-resource";
    // MEG actions
    ActionTypes["MEG_ADD_RESOURCE"] = "meg-add-resource";
    ActionTypes["SET_ACTIVE_MEG"] = "set-active-meg";
    // General biosignal actions
    ActionTypes["SET_ACTIVE_MONTAGE"] = "set-active-montage";
    ActionTypes["SET_DEFAULT_SENSITIVITY"] = "set-default-sensitivity";
    ActionTypes["SET_HIGHPASS_FILTER"] = "set-highpass-filter";
    ActionTypes["SET_LOWPASS_FILTER"] = "set-lowpass-filter";
    ActionTypes["SET_NOTCH_FILTER"] = "set-notch-filter";
    ActionTypes["SET_PAGE_LENGTH"] = "set-page-length";
    // Document actions
    ActionTypes["NEXT_PAGE"] = "next-page";
    ActionTypes["PREVIOUS_PAGE"] = "previous-page";
    ActionTypes["SET_ACTIVE_DOC"] = "set-active-doc";
    ActionTypes["SET_PAGE_NUMBER"] = "set-page-number";
    // ONNX
    ActionTypes["PAUSE_ONNX_RUN"] = "pause-onnx-run";
    ActionTypes["RUN_ONNX_MODEL"] = "run-onnx-model";
    ActionTypes["SET_ONNX_MODEL"] = "set-onnx-model";
})(ActionTypes || (ActionTypes = {}));
const actions = {
    [ActionTypes.ADD_COMPONENT_STYLES](injectee, ...payload) {
        const newStyles = [];
        for (let i = 0; i < payload.length; i++) {
            const styles = Array.isArray(payload[i]) ? payload[i] : [payload[i]];
            for (const style of styles) {
                if (COMPONENT_STYLES_ADDED.indexOf(style) === -1) {
                    // Add new style definition
                    newStyles.push(style);
                    COMPONENT_STYLES_ADDED.push(style);
                }
            }
        }
        // Add any remaining styles
        if (newStyles.length) {
            COMPONENT_STYLES_ADDED.concat(newStyles);
            store.commit('add-styles', newStyles);
        }
    },
    [ActionTypes.ADD_RESOURCE](injectee, payload) {
        if (!store.state.APP.activeDataset) {
            store.dispatch(`add-dataset`);
        }
        store.commit('add-resource', payload);
    },
    [ActionTypes.DISPLAY_VIEWER]() {
        // Broadcast
    },
    [ActionTypes.LOAD_DATASET_FOLDER](injectee, payload) {
        let promiseResolve, promiseReject;
        const promise = new Promise((resolve, reject) => {
            // This promise is resolved when the dataset is done loading
            promiseResolve = resolve;
            promiseReject = reject;
        });
        store.commit('add-dataset', { scope: 'local', name: payload.name });
        store.commit('load-dataset-url', {
            dataset: payload,
            promise: { resolve: promiseResolve, reject: promiseReject },
        });
        return promise;
    },
    [ActionTypes.LOAD_STUDY_FOLDER](injectee, payload) {
        let promiseResolve, promiseReject;
        const promise = new Promise((resolve, reject) => {
            // This promise is resolved when the study folder has loaded
            promiseResolve = resolve;
            promiseReject = reject;
        });
        store.commit('load-study-folder', {
            study: payload,
            promise: { resolve: promiseResolve, reject: promiseReject },
        });
        return promise;
    },
    [ActionTypes.LOAD_STUDY_URL](injectee, payload) {
        let promiseResolve, promiseReject;
        const promise = new Promise((resolve, reject) => {
            promiseResolve = resolve;
            promiseReject = reject;
        });
        store.commit('load-study-url', {
            study: payload,
            promise: { resolve: promiseResolve, reject: promiseReject },
        });
        return promise;
    },
    [ActionTypes.MOUSE_LEFT_APP]() {
        store.commit('hide-overlay');
    },
    [ActionTypes.OVERLAY_CLICKED]() {
        store.commit('hide-overlay');
    },
    [ActionTypes.SET_ACTIVE_RESOURCE](injectee, payload) {
        disableAllOtherResources('', payload);
        store.commit('set-active-resource', payload);
    },
    [ActionTypes.SET_APP_SCOPE](injectee, payload) {
        store.commit('set-app-scope', payload);
    },
    /**
     * Check if given setting can be stored locally and commit it.
     * @param payload - Object containing the settings field and value.
     */
    [ActionTypes.SET_SETTINGS_VALUE](injectee, payload) {
        // Check if this setting can be stored
        const local = window.localStorage.getItem('settings');
        if (local) {
            // Make sure the field contains more than the module name and extract path components
            if (payload.field.includes('.')) {
                const [mod, field] = payload.field.split('.', 2);
                const MODULE = SETTINGS[mod];
                // Check if value can be set
                if (MODULE._userDefinable) {
                    const valConst = MODULE._userDefinable[field];
                    if (valConst === payload.value.constructor) {
                        const localSettings = JSON.parse(local);
                        if (!localSettings[mod]) {
                            localSettings[mod] = {};
                        }
                        localSettings[mod][field] = payload.value;
                        window.localStorage.setItem('settings', JSON.stringify(localSettings));
                        Log.debug(`Saved value ${payload.value} to settings field ${payload.field} locally.`, SCOPE);
                    }
                }
            }
        }
        store.commit('set-settings-value', payload);
    },
    [ActionTypes.TOGGLE_FULLSCREEN]() {
        const appCont = document.querySelector(`#epicv${store.state.containerId}`) || null;
        if (document.fullscreenElement !== null) {
            document.exitFullscreen();
            if (document.fullscreenElement === appCont) {
                return; // Don't re-request fullscreen
            }
        }
        appCont?.requestFullscreen();
    },
    // Datasets
    [ActionTypes.ADD_DATASET](injectee, payload) {
        if (!payload) {
            payload = new MixedMediaDataset(`Dataset ${store.state.APP.datasets.length + 1}`);
        }
        store.commit('add-dataset', payload);
    },
    [ActionTypes.SET_ACTIVE_DATASET](injectee, payload) {
        if (payload === null) {
            store.commit('set-active-dataset', payload);
        }
        else if (typeof payload === 'number') {
            if (store.state.APP.datasets[payload]) {
                store.commit('set-active-dataset', store.state.APP.datasets[payload]);
            }
            else {
                Log.error(`Cannot set active dataset, array index ${payload} is out of bounds.`, SCOPE);
            }
        }
        else {
            store.commit('set-active-dataset', payload);
        }
    },
    // Document
    [ActionTypes.DOC_ADD_RESOURCE](injectee, payload) {
        // Point to add-resource
        actions['add-resource'](injectee, payload);
    },
    [ActionTypes.SET_ACTIVE_DOC](injectee, payload) {
        if (!store.state.DOC.resources) {
            return;
        }
        // Null payload means to disable all
        if (payload === null) {
            disableAllOtherResources('', null);
            store.commit('set-active-resource', null);
            return;
        }
        else {
            //disableAllOtherResources('document', payload)
        }
        // Safety check
        if (store.state.DOC.resources.length <= payload) {
            store.commit('set-active-resource', null);
            return;
        }
        if (!store.state.DOC.resources[payload].isActive && store.state.APP.scope !== 'document') {
            store.commit('set-app-scope', 'document');
        }
        store.commit('set-active-resource', store.state.DOC.resources[payload]);
    },
    // General document
    [ActionTypes.NEXT_PAGE](injectee) {
        if (!store.state.DOC.resources) {
            return;
        }
        const resource = getActiveDocumentResource();
        if (resource && resource.numPages > resource.pageNum) {
            store.commit('set-page-number', { resource: resource, value: resource.pageNum + 1 });
        }
    },
    [ActionTypes.PREVIOUS_PAGE](injectee) {
        if (!store.state.DOC.resources) {
            return;
        }
        const resource = getActiveDocumentResource();
        if (resource && resource.pageNum > 1) {
            store.commit('set-page-number', { resource: resource, value: resource.pageNum - 1 });
        }
    },
    [ActionTypes.SET_PAGE_NUMBER](injectee, payload) {
        if (!store.state.DOC.resources || payload < 1) {
            return;
        }
        const resource = getActiveDocumentResource();
        if (resource && resource.numPages >= payload) {
            store.commit('set-page-number', { resource: resource, value: payload });
        }
    },
    // EEG
    [ActionTypes.EEG_ADD_RESOURCE](injectee, payload) {
        // Point to add-resource
        actions['add-resource'](injectee, payload);
    },
    [ActionTypes.SET_ACTIVE_EEG](injectee, payload) {
        if (!store.state.EEG.resources) {
            return;
        }
        // We first need to disable all other resources
        //disableAllOtherResources('eeg', payload)
        // If payload is null, we just needed to set all recordings as inactive
        if (payload === null || store.state.EEG.resources.length <= payload) {
            // Trigger the generic set-active-resource action
            store.commit('set-active-resource', null);
            return;
        }
        if (!store.state.EEG.resources[payload].isActive && store.state.APP.scope !== 'eeg') {
            store.commit('set-app-scope', 'eeg');
        }
        store.commit('set-active-resource', store.state.EEG.resources[payload]);
    },
    [ActionTypes.SET_ACTIVE_MONTAGE](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource && (resource.montages.length || payload === null)) {
            store.commit('set-active-montage', { resource: resource, montage: payload });
        }
    },
    // EMG
    [ActionTypes.EMG_ADD_RESOURCE](injectee, payload) {
        // Point to add-resource
        actions['add-resource'](injectee, payload);
    },
    [ActionTypes.SET_ACTIVE_EMG](injectee, payload) {
        if (!store.state.EMG.resources) {
            return;
        }
        // We first need to disable all other resources
        //disableAllOtherResources('emg', payload)
        // If payload is null, we just needed to set all recordings as inactive
        if (payload === null || store.state.EMG.resources.length <= payload) {
            // Trigger the generic set-active-resource action
            store.commit('set-active-resource', null);
            return;
        }
        if (!store.state.EMG.resources[payload].isActive && store.state.APP.scope !== 'emg') {
            store.commit('set-app-scope', 'emg');
        }
        store.commit('set-active-resource', store.state.EMG.resources[payload]);
    },
    [ActionTypes.SET_JITTER](injectee, payload) {
        const resource = getActiveBiosignalResource();
        // Can't measure jitter unless triggering potentials
        if (resource && resource.triggered) {
            store.commit('emg-set-jitter', { resource: resource, value: payload });
        }
    },
    [ActionTypes.SET_TRIGGERED](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource) {
            store.commit('emg-set-triggered', { resource: resource, value: payload });
        }
    },
    // NCS
    [ActionTypes.NCS_ADD_RESOURCE](injectee, payload) {
        // Point to add-resource
        actions['add-resource'](injectee, payload);
    },
    [ActionTypes.SET_ACTIVE_ENG](injectee, payload) {
        if (!store.state.NCS.resources) {
            return;
        }
        // We first need to disable all other resources
        //disableAllOtherResources('ncs', payload)
        // If payload is null, we just needed to set all recordings as inactive
        if (payload === null || store.state.NCS.resources.length <= payload) {
            // Trigger the generic set-active-resource action
            store.commit('set-active-resource', null);
            return;
        }
        if (!store.state.NCS.resources[payload].isActive && store.state.APP.scope !== 'ncs') {
            store.commit('set-app-scope', 'ncs');
        }
        store.commit('set-active-resource', store.state.NCS.resources[payload]);
    },
    // MEG
    [ActionTypes.MEG_ADD_RESOURCE](injectee, payload) {
        // Point to add-resource
        actions['add-resource'](injectee, payload);
    },
    [ActionTypes.SET_ACTIVE_MEG](injectee, payload) {
        if (!store.state.EEG.resources) {
            return;
        }
        // We first need to disable all other resources
        //disableAllOtherResources('meg', payload)
        // If payload is null, we just needed to set all recordings as inactive
        if (payload === null || store.state.MEG.resources.length <= payload) {
            // Trigger the generic set-active-resource action
            store.commit('set-active-resource', null);
            return;
        }
        if (!store.state.MEG.resources[payload].isActive && store.state.APP.scope !== 'meg') {
            store.commit('set-app-scope', 'meg');
        }
        store.commit('set-active-resource', store.state.MEG.resources[payload]);
    },
    // General biosignal
    [ActionTypes.SET_DEFAULT_SENSITIVITY](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource && payload > 0) {
            store.commit('set-default-sensitivity', { resource: resource, value: payload });
        }
    },
    [ActionTypes.SET_HIGHPASS_FILTER](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource) {
            store.commit('set-highpass-filter', { resource: resource, value: payload });
        }
    },
    [ActionTypes.SET_LOWPASS_FILTER](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource) {
            store.commit('set-lowpass-filter', { resource: resource, value: payload });
        }
    },
    [ActionTypes.SET_NOTCH_FILTER](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource) {
            store.commit('set-notch-filter', { resource: resource, value: payload });
        }
    },
    [ActionTypes.SET_PAGE_LENGTH](injectee, payload) {
        const resource = getActiveBiosignalResource();
        if (resource && (resource.type === 'emg' || resource.type === 'ncs') && payload > 0) {
            store.commit('set-page-length', { resource: resource, value: payload });
        }
    },
    // ONNX
    [ActionTypes.PAUSE_ONNX_RUN](injectee) {
        store.commit('pause-onnx-run');
    },
    [ActionTypes.RUN_ONNX_MODEL](injectee) {
        store.commit('run-onnx-model');
    },
    [ActionTypes.SET_ONNX_MODEL](injectee, payload) {
        // TODO: Validate model that is requested somehow.
        store.commit('set-onnx-model', payload);
    },
};
export { actions };
