/**
 * EpiCurrents Viewer app constants.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
// 32 bit range
export const NANO = 0.000_000_001;
export const MICRO = 0.000_001;
export const MILLI = 0.001;
export const KILO = 1000;
export const MEGA = 1_000_000;
export const GIGA = 1_000_000_000;
export const KB_BYTES = 1024;
export const MB_BYTES = 1024 * 1024;
export const GB_BYTES = 1024 * 1024 * 1024;
/**
 * Numeric value to return when an error occurred.
 */
export const NUMERIC_ERROR_VALUE = -1;
/**
 * Value indicating that any item in the array is not active.
 */
export const NO_ARRAY_ITEM_ACTIVE = -1;
/**
 * Value indicating that no mouse button is pressed at the moment.
 */
export const NO_MOUSE_BUTTON_DOWN = -1;
