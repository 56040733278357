/**
 * Text file parser.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 *
 * @remarks
 * The File and ArrayBuffer methods are separate because fetch() returns
 * HTTP request results as buffers, but in some cases files might be
 * needed to be loaded directly.
 */
import Log from 'scoped-ts-log';
const SCOPE = 'TextParser';
export class TextParser {
    childParsers = [];
    addChildParser(parser) {
        this.childParsers.push(parser);
    }
    removeChildParser(parser) {
        this.childParsers.splice(this.childParsers.indexOf(parser), 1);
    }
    async parseFile(file, study) {
        if (!this.childParsers.length) {
            Log.error(`${SCOPE} has no no child parsers.`, SCOPE);
            return false;
        }
        const buffer = await file.arrayBuffer();
        return this.parseSource(buffer, study);
    }
    parseLines(lines, study) {
        if (!this.childParsers.length) {
            Log.error(`${SCOPE} has no no child parsers.`, SCOPE);
            return false;
        }
        for (const parser of this.childParsers) {
            if (parser.parseLines(lines, study)) {
                return true;
            }
        }
        return false;
    }
    parseText(text, study) {
        if (!this.childParsers.length) {
            Log.error(`${SCOPE} has no no child parsers.`, SCOPE);
            return false;
        }
        const lines = text.split(/\r\n|\n\r|\n|\r/g);
        return this.parseLines(lines, study);
    }
    async parseSource(source, study) {
        if (!this.childParsers.length) {
            Log.error(`${SCOPE} has no no child parsers.`, SCOPE);
            return false;
        }
        let encoding = 'utf-8';
        // Check for byte order mark
        const firstBytes = new Uint8Array(source.slice(0, 4));
        if ( // UFT-16 big endian and little endian
        (firstBytes[0] === 0xFE && firstBytes[1] === 0xFF) ||
            (firstBytes[0] === 0xFF && firstBytes[1] === 0xFE && firstBytes[2] !== 0x00)) {
            Log.debug(`Text encoding is UTF-16`, SCOPE);
            encoding = 'utf-16';
        }
        else if ( // UTF-16 big endian and little endian
        (firstBytes[0] === 0x00 && firstBytes[1] === 0x00 && firstBytes[2] === 0xFE && firstBytes[3] === 0xFF) ||
            (firstBytes[0] === 0xFF && firstBytes[1] === 0xFE && firstBytes[2] === 0x00 && firstBytes[3] === 0x00)) {
            Log.debug(`Text encoding is UTF-32`, SCOPE);
            encoding = 'utf-32';
        }
        const decoder = new TextDecoder(encoding);
        const text = decoder.decode(source);
        return this.parseText(text, study);
    }
}
