/**
 * EpiCurrents Viewer markdown file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import GenericFileLoader from '../lib/loaders/GenericFileLoader';
import { SierraEmgParser } from './parsers/SierraEmgParser';
import { TextParser } from './parsers/TextParser';
import Log from 'scoped-ts-log';
const SCOPE = 'CsvFileLoader';
export default class CsvFileLoader extends GenericFileLoader {
    constructor() {
        super(SCOPE, [], ['.csv']);
    }
    async _parseFile(source, config = {}) {
        const parser = new TextParser();
        parser.addChildParser(new SierraEmgParser());
        if (await parser.parseSource(source, this._study)) {
            this._study.scope = 'emg';
            return 'sig:emg';
        }
        else {
            return false;
        }
    }
    async loadFile(source, config = {}) {
        const file = source.file || source;
        Log.debug(`Attempting to parse CSV file ${file.webkitRelativePath}.`, SCOPE);
        const fileType = await this._parseFile(await file.arrayBuffer());
        if (fileType) {
            const studyFile = {
                file: null,
                format: 'text',
                mime: config?.mime || 'text/csv',
                name: config?.name || file.name || '',
                type: fileType,
                url: config?.url || URL.createObjectURL(file),
            };
            this._study.files.push(studyFile);
            return studyFile;
        }
        return null;
    }
    async loadFileUrl(source, config = {}) {
        const url = source.url || source;
        Log.debug(`Attempting to parse CSV file from URL ${url}.`, SCOPE);
        // Check if this is a signal data file
        return await fetch(url).then(response => response.arrayBuffer()).then(async (buffer) => {
            const fileType = await this._parseFile(buffer);
            if (fileType) {
                const studyFile = {
                    file: null,
                    format: 'text',
                    mime: config?.mime || 'text/csv',
                    name: config?.name || '',
                    type: fileType,
                    url: config?.url || url,
                };
                this._study.files.push(studyFile);
                return studyFile;
            }
            else {
                return null;
            }
        });
    }
}
