/**
 * EpiCurrents Viewer WAV file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import GenericFileLoader from '../lib/loaders/GenericFileLoader';
import Log from 'scoped-ts-log';
const SCOPE = 'SynergyWavFileLoader';
export default class SynergyWavFileLoader extends GenericFileLoader {
    constructor() {
        super(SCOPE, [], ['.wav']);
    }
    _addWavChannel(config = {}) {
        // WAV files are most like EMG signal recordings.
        if (this._study.meta.channels === undefined) {
            this._study.meta.channels = [];
        }
        this._study.meta.channels.push({
            label: 'EMG',
            name: 'EMG',
            type: 'emg',
            samplingRate: 0,
            amplification: 50_000,
            sensitivity: 0,
            signal: new Float32Array(),
            unit: 'uV',
            sampleCount: 0,
        });
        this._study.format = 'wav';
        this._study.type = 'sig:emg';
    }
    async loadFile(source, config = {}) {
        const file = source.file || source;
        Log.debug(`Adding a WAV file ${file.webkitRelativePath}.`, SCOPE);
        const studyFile = {
            file: file,
            format: 'wav',
            mime: config?.mime || 'audio/wav',
            name: config?.name || file.name || '',
            type: '',
            url: config?.url || URL.createObjectURL(file),
        };
        this._addWavChannel(config);
        this._study.files.push(studyFile);
        return studyFile;
    }
    async loadFileUrl(source, config = {}) {
        const url = source.url || source;
        Log.debug(`Adding a WAV url ${url}.`, SCOPE);
        const studyFile = {
            file: null,
            format: 'wav',
            mime: config?.mime || 'audio/wav',
            name: config?.name || '',
            type: '',
            url: config?.url || url,
        };
        this._addWavChannel(config);
        this._study.files.push(studyFile);
        return studyFile;
    }
}
