/**
 * EpiCurrents Viewer loader memory manager.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import SETTINGS from "CONFIG/Settings";
import { NUMERIC_ERROR_VALUE } from "LIB/util/constants";
import { nullPromise } from "LIB/util/general";
import Log from "scoped-ts-log";
const SCOPE = 'LoaderMemoryManager';
export default class LoaderMemoryManager {
    static MASTER_LOCK_POS = 0;
    static BUFFER_START_POS = 1;
    /**
     * The total memory buffer available to this application.
     */
    _buffer;
    _commissions = {
        'release-and-rearrange': null,
    };
    _decommissionWorker = null;
    /**
     * The loaders managed by this memory manager.
     * The only reference to these managers can only be through this
     * list, so they can be freed to GC by dereferencing.
     */
    _managers = new Map();
    _masterLock;
    _requestNum = 0;
    /**
     * Worker assigned to execute atomic operations on the memory buffer.
     */
    _worker;
    /**
     * Create an instance of LoaderMemoryManager with the given buffer size.
     * @param bufferSize - The total size of the buffer in bytes; from this on, sizes are always in 32-bit units.
     */
    constructor(bufferSize) {
        this._buffer = new SharedArrayBuffer(bufferSize + 4);
        this._masterLock = new Int32Array(this._buffer).subarray(LoaderMemoryManager.MASTER_LOCK_POS, LoaderMemoryManager.BUFFER_START_POS);
        this._worker = new Worker(new URL(`LIB/workers/MemoryManagerWorker.ts`, import.meta.url));
        this._worker.addEventListener('message', this._handleMessage.bind(this));
        this._worker.postMessage({
            action: 'set-buffer',
            buffer: this._buffer,
        });
    }
    get buffer() {
        return this._buffer;
    }
    get bufferSize() {
        return this._buffer.byteLength / 4;
    }
    get freeMemory() {
        return this.bufferSize - this.memoryUsed;
    }
    get loaders() {
        const loaders = [];
        for (const l of this._managers.values()) {
            loaders.push(l.loader);
        }
        return loaders;
    }
    get memoryUsed() {
        let totalUsed = 0;
        for (const loader of this._managers.values()) {
            totalUsed += loader.loader.memoryConsumption;
        }
        return totalUsed / 4;
    }
    _commissionWorker(action, callbacks, props) {
        if (!this._worker) {
            return {
                promise: nullPromise,
                requestNum: NUMERIC_ERROR_VALUE
            };
        }
        const returnPromise = new Promise((resolve, reject) => {
            callbacks.resolve = resolve;
            if (callbacks.reject) {
                callbacks.reject = reject;
            }
        });
        const requestNum = this._requestNum++;
        const msgData = {
            action: action,
            rn: requestNum
        };
        if (props) {
            for (const [key, value] of props) {
                msgData[key] = value;
            }
        }
        this._worker.postMessage(msgData);
        return {
            promise: returnPromise,
            requestNum: requestNum
        };
    }
    _handleMessage(message) {
        const action = message?.data?.action;
        if (!action || action === 'set-buffer') {
            return;
        }
        if (action === 'log') {
            Log.add(message.data.level, message.data.message, SCOPE);
            return;
        }
        const commission = this._commissions[action];
        if (!commission) {
            Log.error(`Received a message from the worker, but no commission matched the action '${action}'.`, SCOPE);
            return;
        }
        if (message.data.rn !== commission.rn) {
            Log.debug(`Ignoring a response from the worker with outdated request number '${message.data.rn}'.`, SCOPE);
            return;
        }
        if (message.data.success) {
            commission.resolve(message.data.result);
        }
        else {
            if (commission.reject) {
                commission.reject(message.data.reason);
            }
            else {
                commission.resolve(null);
            }
        }
    }
    async allocate(amount, loader) {
        // Correct amount to a 32-bit array size
        amount = amount + (4 - amount % 4);
        // Don't exceed maximum allowed buffer size
        if (amount > SETTINGS.app.maxLoadCacheSize) {
            Log.error(`Tried to allocate an array that exceeds maximum allowed buffer size.`, SCOPE);
            return null;
        }
        if (amount < 0) {
            Log.error(`Cannot allocate a buffer array with negative length.`, SCOPE);
            return null;
        }
        // Zero means use the entire buffer
        if (amount === 0) {
            amount = SETTINGS.app.maxLoadCacheSize - SETTINGS.app.maxLoadCacheSize % 4;
        }
        // Do not assign memory twice for the same loader
        for (const existing of this._managers) {
            if (existing[1].loader.id === loader.id) {
                Log.error(`The loader passed to assign was already in managed loaders.`, SCOPE);
                return null;
            }
        }
        // Check if we have memory to spare and try to free some if needed
        let totalUsed = 0;
        for (const manager of this._managers.values()) {
            totalUsed += manager.bufferRange[1] - manager.bufferRange[0];
        }
        const delta = Math.max(0, amount - (this.bufferSize - totalUsed));
        if (delta && !(await this.freeBy(delta))) {
            Log.warn(`Could not free the required amount of memory to assign to a new loader.`, SCOPE);
            return null;
        }
        // Find the end of the allocated buffer range from remaining managers
        let endIndex = 0;
        for (const manager of this._managers.values()) {
            if (manager.bufferRange[1] > endIndex) {
                endIndex = manager.bufferRange[1];
            }
        }
        this._managers.set(loader.id, {
            bufferRange: [endIndex, endIndex + amount],
            lastUsed: Date.now(),
            dependencies: [],
            loader: loader,
        });
        return { start: endIndex, end: endIndex + amount };
    }
    async freeBy(amount, ignore = []) {
        if (this._managers.size < 2) {
            return false;
        }
        // Sort the loaders to find from most to least recently used
        const sorted = [...this._managers.values()];
        sorted.sort((a, b) => a.lastUsed - b.lastUsed);
        let totalFreed = 0;
        const rangesFreed = [];
        while (sorted.length > 1) {
            const nextToDrop = sorted.pop();
            if (!ignore.length || ignore.indexOf(nextToDrop.loader.id)) {
                await nextToDrop.loader.unload();
                rangesFreed.push(nextToDrop.bufferRange);
                this._managers.delete(nextToDrop.loader.id);
                if (totalFreed >= amount) {
                    // Rearrange buffer and report success
                    this.removeFromBuffer(...rangesFreed);
                    return true;
                }
            }
        }
        // Rearrange buffer and report failure to free the requested space
        if (rangesFreed.length) {
            this.removeFromBuffer(...rangesFreed);
        }
        return false;
    }
    getLoader(id) {
        return (this._managers.get(id)?.loader || null);
    }
    async release(loader) {
        if (typeof loader === 'string') {
            const manager = this._managers.get(loader);
            if (!manager) {
                Log.error(`Could not release loader; no loader with such id was found.`, SCOPE);
                return false;
            }
            await this.removeFromBuffer(manager.bufferRange);
        }
        else {
            const manager = this._managers.get(loader.id);
            if (!manager) {
                Log.error(`Could not release loader; the given loader was not among managed loaders.`, SCOPE);
                return false;
            }
            await this.removeFromBuffer(manager.bufferRange);
        }
        return true;
    }
    async removeFromBuffer(...ranges) {
        // Check for and remove possible empty or invalid ranges
        for (let i = 0; i < ranges.length; i++) {
            if (ranges[i][0] === ranges[i][1] || ranges[i][0] > ranges[i][1]) {
                ranges.splice(i, 1);
                i--;
            }
        }
        // Find and remove any current loaders that use one of the ranges
        for (const range of ranges) {
            for (const manager of this._managers.values()) {
                if ((range[0] > manager.bufferRange[0] && range[0] < manager.bufferRange[1]) ||
                    (range[1] > manager.bufferRange[0] && range[1] < manager.bufferRange[1]) ||
                    (range[0] <= manager.bufferRange[0] && range[1] >= manager.bufferRange[1])) {
                    await manager.loader.unload();
                    // Correct the range to reflect the removed loader
                    if (manager.bufferRange[0] < range[0]) {
                        range[0] = manager.bufferRange[0];
                    }
                    if (manager.bufferRange[1] > range[1]) {
                        range[1] = manager.bufferRange[1];
                    }
                    this._managers.delete(manager.loader.id);
                }
            }
        }
        // Commission worker to fill possible empty spaces by shifting following ranges down
        const loaderRanges = [];
        for (const manager of this._managers.values()) {
            loaderRanges.push({
                id: manager.loader.id,
                range: manager.bufferRange
            });
        }
        const callbacks = {
            resolve: () => { }
        };
        const commission = this._commissionWorker('remove-and-rearrange', callbacks, new Map([
            ['rearrange', loaderRanges],
            ['remove', ranges],
        ]));
        this._commissions['remove-and-rearrange'] = {
            rn: commission.requestNum,
            resolve: callbacks.resolve
        };
        const result = await commission.promise;
        if (result.success) {
            for (const rearranged of result.rearrange) {
                const manager = this._managers.get(rearranged.id);
                if (!manager) {
                    Log.error(`Could not find the manager for a loader returned by worker remove-and-rearrange.`, SCOPE);
                    continue;
                }
                manager.bufferRange = rearranged.range;
                // TODO: Relay the new range to the loader as well
            }
        }
    }
    updateLastUsed(loader) {
        const timestamp = Date.now();
        loader.lastUsed = timestamp;
        for (const linked of loader.dependencies) {
            // Give the dependency a slightly higher priority, so this is always
            // released before its dependency. It's easier to reload this data
            // straight from the dependency than first reloading the dependency.
            linked.lastUsed = timestamp + 1;
        }
    }
}
