/**
 * EpiCurrents Viewer markdown file loader.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import GenericFileLoader from '../lib/loaders/GenericFileLoader';
import Log from 'scoped-ts-log';
const SCOPE = 'MarkdownFileLoader';
export default class MarkdownFileLoader extends GenericFileLoader {
    constructor() {
        super(SCOPE, [], ['.md', '.markdown']);
    }
    async loadFile(source, config = {}) {
        const file = source.file || source;
        Log.debug(`Adding a Markdown file ${file.webkitRelativePath}.`, SCOPE);
        const studyFile = {
            file: file,
            format: 'markdown',
            mime: config?.mime || 'text/markdown',
            name: config?.name || file.name || '',
            type: '',
            url: config?.url || URL.createObjectURL(file),
        };
        this._study.type = 'doc:htm';
        this._study.format = 'markdown';
        this._study.files.push(studyFile);
        return studyFile;
    }
    async loadFileUrl(source, config = {}) {
        const url = source.url || source;
        Log.debug(`Adding a Markdown url ${url}.`, SCOPE);
        const studyFile = {
            file: null,
            format: 'markdown',
            mime: config?.mime || 'text/markdown',
            name: config?.name || '',
            type: '',
            url: config?.url || url,
        };
        this._study.type = 'doc:htm';
        this._study.format = 'markdown';
        this._study.files.push(studyFile);
        return studyFile;
    }
}
