/**
 * EpiCurrents Viewer Vuex store mutations.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
import { store } from 'STORE';
import SETTINGS from 'CONFIG/Settings';
import { hexToSettingsColor, rgbaToSettingsColor } from 'LIB/util/conversions';
import Log from 'scoped-ts-log';
const SCOPE = "StoreMutations";
var MutationTypes;
(function (MutationTypes) {
    MutationTypes["ADD_RESOURCE"] = "add-resource";
    MutationTypes["ADD_STYLES"] = "add-styles";
    MutationTypes["HIDE_OVERLAY"] = "hide-overlay";
    MutationTypes["LOAD_DATASET_FOLDER"] = "load-dataset-folder";
    MutationTypes["LOAD_STUDY_FOLDER"] = "load-study-folder";
    MutationTypes["LOAD_STUDY_URL"] = "load-study-url";
    MutationTypes["SET_ACTIVE_RESOURCE"] = "set-active-resource";
    MutationTypes["SET_APP_ID"] = "set-app-id";
    MutationTypes["SET_APP_SCOPE"] = "set-app-scope";
    MutationTypes["SET_FULLSCREEN"] = "set-fullscreen";
    MutationTypes["SET_SETTINGS_VALUE"] = "set-settings-value";
    MutationTypes["SHOW_OVERLAY"] = "show-overlay";
    MutationTypes["TOGGLE_SETTINGS"] = "toggle-settings";
    // Datasets
    MutationTypes["ADD_DATASET"] = "add-dataset";
    MutationTypes["SET_ACTIVE_DATASET"] = "set-active-dataset";
    // Module mutations
    MutationTypes["EMG_SET_JITTER"] = "emg-set-jitter";
    MutationTypes["EMG_SET_TRIGGERED"] = "emg-set-triggered";
    MutationTypes["SET_ACTIVE_MONTAGE"] = "set-active-montage";
    MutationTypes["SET_DEFAULT_SENSITIVITY"] = "set-default-sensitivity";
    MutationTypes["SET_HIGHPASS_FILTER"] = "set-highpass-filter";
    MutationTypes["SET_LOWPASS_FILTER"] = "set-lowpass-filter";
    MutationTypes["SET_NOTCH_FILTER"] = "set-notch-filter";
    MutationTypes["SET_PAGE_LENGTH"] = "set-page-length";
    MutationTypes["SET_PAGE_NUMBER"] = "set-page-number";
    // ONNX mutations
    MutationTypes["PAUSE_ONNX_RUN"] = "pause-onnx-run";
    MutationTypes["RUN_ONNX_MODEL"] = "run-onnx-model";
    MutationTypes["SET_ONNX_MODEL"] = "set-onnx-model";
})(MutationTypes || (MutationTypes = {}));
const mutations = {
    [MutationTypes.ADD_RESOURCE](state, payload) {
        if (!state.APP.activeDataset) {
            Log.error(`Tried to add a resource without an active dataset.`, SCOPE);
            return;
        }
        for (const existing of state.APP.activeDataset.resources) {
            if (existing.id === payload.resource.id) {
                Log.warn(`Tried to add a resource that already exists in current dataset.`, SCOPE);
                return;
            }
        }
        state.APP.activeDataset.addResource(payload.resource);
    },
    [MutationTypes.ADD_STYLES](state, payload) {
        // I like to keep DOM manipulation in the Vue components, so, this
        // method is only to trigger an update in the root application.
    },
    [MutationTypes.HIDE_OVERLAY](state) {
        state.showOverlay = false;
    },
    [MutationTypes.LOAD_DATASET_FOLDER](state, payload) {
        // Broadcast here
    },
    [MutationTypes.LOAD_STUDY_FOLDER](state, payload) {
        // Broadcast here
    },
    [MutationTypes.LOAD_STUDY_URL](state, payload) {
        // Broadcast here
    },
    [MutationTypes.SHOW_OVERLAY](state) {
        state.showOverlay = true;
    },
    [MutationTypes.SET_ACTIVE_RESOURCE](state, payload) {
        payload.isActive = !payload.isActive;
    },
    [MutationTypes.SET_APP_ID](state, payload) {
        state.APP.id = payload;
    },
    [MutationTypes.SET_APP_SCOPE](state, payload) {
        state.APP.scope = payload;
        // Load the appropriate context in pyodide
        if (payload === 'eeg' && state.SETTINGS.modules.MNE && state.PYODIDE) {
            state.PYODIDE.setupContext('eeg').then(() => {
                state.PYODIDE?.runScript('eeg_load_topomap', {});
            });
        }
    },
    [MutationTypes.SET_FULLSCREEN](state, payload) {
        state.isFullscreen = payload;
    },
    [MutationTypes.SET_SETTINGS_VALUE](state, payload) {
        // Traverse field's "path" to target property
        const fPath = payload.field.split('.');
        // The value must be updated both in local app state and global settings
        let localField = [state.SETTINGS];
        let globalField = [SETTINGS];
        let i = 0;
        for (const f of fPath) {
            if (i === fPath.length - 1) {
                if (localField[i] === undefined || globalField[i] === undefined ||
                    localField[i][f] === undefined ||
                    globalField[i][f] === undefined) {
                    Log.warn(`Default configuration field '
                        ${payload.field}
                        ' is invalid: cannot find property '
                        ${fPath.slice(0, i + 1).join('.')}
                        '. Valid properties are '
                        ${Object.keys(localField[i]).join("', '")}
                        '.`, SCOPE);
                    return;
                }
                // Final field
                const local = localField.pop();
                const global = globalField.pop();
                if (typeof payload.value === 'string') {
                    // Parse possible color code
                    payload.value = rgbaToSettingsColor(payload.value) ||
                        hexToSettingsColor(payload.value) ||
                        payload.value;
                }
                // Typecheck
                if (local[f].constructor === payload.value.constructor) {
                    local[f] = payload.value;
                }
                if (global[f].constructor === payload.value.constructor) {
                    global[f] = payload.value;
                }
                return;
            }
            else {
                localField.push(localField[i][f]);
                globalField.push(globalField[i][f]);
            }
            i++;
        }
    },
    [MutationTypes.TOGGLE_SETTINGS](state, payload) {
        if (payload !== undefined) {
            state.settingsOpen = payload;
        }
        else {
            state.settingsOpen = !state.settingsOpen;
        }
    },
    // Datasets
    [MutationTypes.ADD_DATASET](state, payload) {
        state.APP.datasets.push(payload);
        Log.debug(`${payload.name} added.`, SCOPE);
        if (!state.APP.activeDataset) {
            store?.dispatch('set-active-dataset', payload);
        }
    },
    [MutationTypes.SET_ACTIVE_DATASET](state, payload) {
        if (state.APP.activeDataset) {
            state.APP.activeDataset.isActive = false;
        }
        state.APP.activeDataset = payload;
        if (payload) {
            payload.isActive = true;
        }
    },
    // Add module mutations
    [MutationTypes.EMG_SET_JITTER](state, payload) {
        payload.resource.jitter = payload.value;
    },
    [MutationTypes.EMG_SET_TRIGGERED](state, payload) {
        payload.resource.triggered = payload.value;
    },
    [MutationTypes.SET_ACTIVE_MONTAGE](state, payload) {
        payload.resource.setActiveMontage(payload.montage);
    },
    [MutationTypes.SET_DEFAULT_SENSITIVITY](state, payload) {
        payload.resource.setDefaultSensitivity(payload.value);
    },
    [MutationTypes.SET_HIGHPASS_FILTER](state, payload) {
        payload.resource.setHighpassFilter(payload.value);
    },
    [MutationTypes.SET_LOWPASS_FILTER](state, payload) {
        payload.resource.setLowpassFilter(payload.value);
    },
    [MutationTypes.SET_NOTCH_FILTER](state, payload) {
        payload.resource.setNotchFilter(payload.value);
    },
    [MutationTypes.SET_PAGE_LENGTH](state, payload) {
        payload.resource.pageLength = payload.value;
    },
    [MutationTypes.SET_PAGE_NUMBER](state, payload) {
        payload.resource.pageNum = payload.value;
    },
    // ONNX
    [MutationTypes.PAUSE_ONNX_RUN](state) {
        state.ONNX?.pauseRun();
    },
    [MutationTypes.RUN_ONNX_MODEL](state) {
        state.ONNX?.run();
    },
    [MutationTypes.SET_ONNX_MODEL](state, payload) {
        state.ONNX?.loadModel(payload);
    },
};
export { mutations };
