/**
 * EpiCurrents Viewer mixed media dataset for all media types.
 * @package    epicurrents-viewer
 * @copyright  2023 Sampsa Lohi
 * @license    MIT
 */
import GenericDataset from "LIB/common/GenericDataset";
export default class MixedMediaDataset extends GenericDataset {
    /**
     * Create a new media dataset with the given properties.
     * @param name - Name of the dataset.
     */
    constructor(name) {
        super(name);
    }
    get resources() {
        return this._resources;
    }
    set resources(value) {
        this._resources = value;
        this.onPropertyUpdate('resources');
    }
    addResource(resource) {
        super.addResource(resource);
    }
    removeResource(resource) {
        super.removeResource(resource);
    }
}
