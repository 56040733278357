/**
 * EpiCurrents Viewer general utilities.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
/**
 * Enumerate over an array, returning [index, item].
 * @param iterable any array
 * @example
 * for (const [i, item] of enumerate(iterableArray)) {
 *      console.log(iterableArray[i] === item) // true
 * }
 */
export const enumerate = function* (iterable) {
    let i = 0;
    for (const x of iterable) {
        yield [i, x];
        i++;
    }
};
/**
 * Check if the given object is empty, i.e. doesn't have own properties.
 * @param obj - object to check
 * @returns true/false
 */
export const isEmptyObject = (obj) => {
    if (obj
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype) {
        return true;
    }
    return false;
};
/**
 * Get the value stored at the given `key` in the target Map.
 * If the key does not exist, it will be initiated with the given `value`
 * and a reference to the set value is returned.
 * @param map - The target Map.
 * @param key - Key to look for.
 * @param value - Default value to use as initiator, if the `key` doesn't exist.
 */
export const getOrSetValue = (map, key, value) => {
    return map.has(key) ? map.get(key) : map.set(key, value).get(key);
};
/**
 * A promise that immediately returns the value null.
 */
export const nullPromise = Promise.resolve(null);
/**
 * Create a safe (no prototype injection) object with the provided properties.
 * @param template - Object with properties to copy.
 * @returns safe object
 */
export const safeObjectFrom = (template) => {
    return Object.assign(Object.create(null), template);
};
/**
 * Returns a promise that will fulfill after the set amount of time.
 * Can be used to delay the execution of following code.
 * @param duration duration to sleep for in milliseconds
 * @returns Promise
 * @example
 * const [item] = await Promise.all([
 *     queryItem(nextItem),
 *     sleep(1000)
 * ])
 */
export const sleep = async (duration) => {
    return new Promise(resolve => setTimeout(resolve, duration));
};
