/**
 * EpiCurrents Viewer EEG settings.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
export const MEG = {
    // Display settings
    annotations: {
        color: [0, 0, 1, 0.75],
        convertPatterns: [],
        idColors: {
            'act_ec': [0.25, 0.5, 0.25, 0.75],
            'act_eo': [0.25, 0.5, 0.25, 0.75],
            'act_hv': [0.6, 0, 0.4, 0.75],
            'act_phv': [0.4, 0.2, 0.4, 0.75],
            'act_ps': [0.5, 0.5, 0, 0.75],
        },
        ignorePatterns: [
            '^Trigger-\\d+$'
        ],
        typeColors: {
            'activation': [0, 1, 0, 0.75],
            'technical': [0.5, 0.5, 0.5, 0.75],
        },
        width: 1,
    },
    antialiasing: false,
    border: {
        bottom: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 2,
        },
        left: {
            color: [0.8, 0.8, 0.8, 1],
            style: 'solid',
            width: 2,
        },
    },
    channelSpacing: 1,
    continuousBrowseDelay: 500,
    continuousBrowseInterval: 100,
    cursor: {
        color: [0.5, 0, 0, 0.4],
        width: 3,
    },
    defaultMontages: {
        '10-20': [
            ['rec', 'As recorded'],
            ['avg', 'Average reference'],
            ['dbn', 'Double banana'],
            ['trv', 'Transverse'],
            ['lpl', 'Source Laplacian'],
        ],
        'hus-meg': [
            ['frec', 'Frontal'],
            ['ftrv', 'Frontal transverse'],
            ['cprec', 'Centro-parietal'],
            ['trec', 'Temporal'],
            ['orec', 'Occipital'],
        ],
    },
    defaultSetups: [
        '10-20',
        'hus-meg',
    ],
    displayPolarity: -1,
    downsampleLimit: 250,
    excludeActiveFromAvg: false,
    fft: {
        frequencyBands: [
            { name: 'delta', symbol: 'δ', upperLimit: 4 },
            { name: 'theta', symbol: 'θ', upperLimit: 8 },
            { name: 'alpha', symbol: 'α', upperLimit: 13 },
            { name: 'beta', symbol: 'β', upperLimit: 30 },
        ],
    },
    filterPaddingSeconds: 10,
    filters: {
        highpass: {
            availableValues: [0, 0.1, 0.3, 0.5, 0.7, 1, 1.5, 2, 2.5, 3, 4, 5],
            default: 0.3,
        },
        lowpass: {
            availableValues: [0, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 100],
            default: 70,
        },
        notch: {
            availableValues: [0, 50, 60],
            default: 0,
        },
        usePython: false,
    },
    groupSpacing: 1.5,
    isoelLine: {
        show: false,
        color: [0.9, 0.9, 0.9, 1],
        style: 'solid',
        width: 1,
    },
    majorGrid: {
        show: true,
        color: [0, 0, 0, 0.25],
        style: 'solid',
        width: 2,
    },
    maxNewSignalCacheCycleLength: 300,
    minNewSignalCacheCycleLength: 60,
    minorGrid: {
        show: true,
        color: [0, 0, 0, 0.15],
        style: 'solid',
        width: 1,
    },
    montages: {
        cacheMax: 2,
        preCache: false,
    },
    navigator: {
        annotationColor: [0, 0, 1, 0.5],
        borderColor: [0, 0, 0, 0.2],
        cachedColor: [0, 0.75, 0, 0.5],
        gapColor: [0, 0, 0, 0.1],
        loadedColor: [0, 0.35, 0, 0.5],
        loadingColor: [0.05, 0.20, 0.05, 0.5],
        theme: 'default',
        tickColor: [0, 0, 0, 0.2],
        viewBoxColor: [1, 0.2, 0.2, 0.25],
    },
    pageLength: 10,
    secPerPage: 10,
    sensitivity: {
        fTperCm: {
            availableValues: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1_000, 1_200, 1_500, 2_000, 2_500, 3_000, 4_000, 5_000, 10_000],
            default: 500,
        }
    },
    sensitivityUnit: 'fTperCm',
    showHiddenChannels: false,
    showMissingChannels: false,
    timebase: {
        cmPerS: {
            availableValues: [3],
            default: 3,
        },
    },
    timebaseUnit: 'cmPerS',
    timeline: {
        labelSpacing: 2,
    },
    tools: {
        cursorLine: {
            color: [0.5, 0, 0, 0.4],
            style: 'solid',
            width: 2,
        },
        excludeArea: {
            color: [0.5, 0.5, 0.5, 0.2],
            style: 'solid',
            width: 1,
        },
        guideLine: {
            color: [0.5, 0.5, 0.5, 0.25],
            style: 'solid',
            width: 1,
        },
        guideLineSymbol: {
            color: [0.5, 0.5, 0.5, 0.5],
        },
        highlightArea: {
            color: [1, 1, 0.5, 0.5],
        },
        poiMarkerLine: {
            color: [0.9, 0.7, 0.6, 1],
            dasharray: [2, 1],
            style: 'dashed',
            width: 1,
        },
        poiMarkerCircle: {
            color: [0.9, 0.7, 0.6, 1],
            radius: 5,
            style: 'solid',
            width: 2,
        },
        signals: [
            {
                color: [0, 0.4, 0.9, 1],
                style: 'solid',
                width: 1,
            },
            {
                color: [0.75, 0, 0.2, 1],
                style: 'solid',
                width: 1,
            },
        ],
        signalBaseline: {
            color: [0.9, 0.8, 0.8, 1],
            dasharray: [8, 2],
            style: 'dashed',
            width: 1,
        },
    },
    trace: {
        color: {
            eeg: [0, 0, 0, 1],
            sin: [0.5, 0, 0, 1],
            dex: [0, 0, 0.5, 1],
            mid: [0, 0.5, 0, 1],
            ekg: [0.75, 0, 0, 1],
            emg: [0.3, 0, 0, 1],
            eog: [0, 0, 0.75, 1],
            meg: [0, 0, 0, 1],
            res: [0, 0.8, 0, 1],
            act: [0.1, 0.1, 0.1, 1],
            meta: [0, 0, 0, 0],
            default: [0.2, 0.2, 0.2, 1],
        },
        colorSides: false,
        selections: {
            color: [0, 0, 1, 0.075],
        },
        theme: 'default',
        width: {
            eeg: 1,
            ekg: 1,
            eog: 1,
            meg: 1,
        },
    },
    yPadding: 1,
};
