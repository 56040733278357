/**
 * EpiCurrents Viewer Vuex store MEG module.
 * @package    epicurrents-viewer
 * @copyright  2021 Sampsa Lohi
 * @license    MIT
 */
const meg = {
    moduleName: {
        code: 'meg',
        full: 'Magnetoencephalography',
        short: 'MEG',
    },
    resources: [],
};
const getters = {
    activeMegResource: (state) => () => {
        for (const resource of meg.resources) {
            if (resource.isActive) {
                return resource;
            }
        }
        return null;
    }
};
export { meg, getters };
