/**
 * Sierra extension for the text file parser.
 * @package    epicurrents-viewer
 * @copyright  2022 Sampsa Lohi
 * @license    MIT
 */
import Log from 'scoped-ts-log';
import { TextParser } from "./TextParser";
const SCOPE = 'SierraParser';
export class SierraEmgParser extends TextParser {
    parseLines(lines, study) {
        let protocol = '';
        let muscle = '';
        let side = 0;
        let ampScale = 1;
        let sRate = 0;
        let nSamples = 0;
        let atData = 0;
        let data = null;
        const range = [0, 0];
        const studyData = {
            name: '',
            test: '',
            type: '',
            muscles: [],
        };
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (!line.length) {
                continue; // This should only happen at the end of the file
            }
            if (data && atData) {
                if (i - atData > data.length - 1) {
                    Log.error(`Imported file had more data rows (${i - atData}) than reported samples (${nSamples}).`, SCOPE);
                    return false;
                }
                const parsedValue = parseFloat(line.substring(1).replace(',', '.'));
                if (parsedValue < range[0]) {
                    range[0] = parsedValue;
                }
                else if (parsedValue > range[1]) {
                    range[1] = parsedValue;
                }
                data.set([parsedValue], i - atData);
                continue;
            }
            const newProperty = line.match(/^([^;]+);(.+)$/);
            if (newProperty) {
                const name = newProperty[1];
                const value = newProperty[2];
                // This complicated contraption is needed because Sierra (against all reason)
                // uses the non-ASCII character 'µ' in their CSV exports (instead of just 'u').
                switch (true) {
                    case /Test Name/i.test(name):
                        protocol = value;
                        break;
                    case /TestItem/i.test(name):
                        if (value.startsWith('Right ')) {
                            side = 2;
                            muscle = value.substring(6);
                        }
                        else if (value.startsWith('Left ')) {
                            side = 1;
                            muscle = value.substring(5);
                        }
                        else {
                            muscle = value;
                        }
                        break;
                    case /Amplitude Scale \(.V\/Bit\)/i.test(name):
                        ampScale = parseFloat(value.replace(',', '.'));
                        break;
                    case /ms\/Sample/i.test(name):
                        sRate = 1000 / parseFloat(value.replace(',', '.'));
                        break;
                    case /Samples/i.test(name):
                        nSamples = parseInt(value);
                        data = new Float32Array(nSamples);
                        break;
                    case /Trace Data \(.V\)/i.test(name):
                        if (!sRate || !nSamples) {
                            Log.error(`Reached trace data field before sampling rate or sample count was parsed.`, SCOPE);
                            return false;
                        }
                        atData = i;
                        const parsedValue = parseFloat(value.replace(',', '.'));
                        if (parsedValue < range[0]) {
                            range[0] = parsedValue;
                        }
                        else if (parsedValue > range[1]) {
                            range[1] = parsedValue;
                        }
                        data?.set([parsedValue], 0);
                        break;
                }
            }
        }
        if (!data) {
            Log.error(`File did not contain any signal samples.`, SCOPE);
            return false;
        }
        if (data.length !== nSamples) {
            Log.warn(`File did not have the reported amount of samples (${data.length} parsed, ${nSamples} expected).`, SCOPE);
        }
        // TODO: Can files contain more than one muscle, e.g. an antagonist-antagonist pair?
        studyData.muscles.push({
            duration: nSamples / sRate,
            name: muscle,
            range: range,
            samplingRate: sRate,
            side: side,
            signal: data
        });
        if (!study.name) {
            study.name = muscle;
        }
        study.type = 'sig:emg';
        study.data = studyData;
        return true;
    }
}
